import HttpStatus from 'http-status-codes'
import { API_CALL_STATE } from '../utils/api'

const API_CALL_START = 'API_CALL_START'
const API_CALL_FINISH = 'API_CALL_FINISH'

export const apiCallStartAction = apiName => ({
  type: API_CALL_START,
  payload: {
    apiName
  }
})

export const apiCallFinishAction = (apiName, statusCode, statusText) => ({
  type: API_CALL_FINISH,
  payload: {
    apiName,
    statusCode,
    statusText,
  }
})

const initialState = {

}

export default function apiCallReducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_START:
      return {
        ...state,
        [action.payload.apiName]: {
          state: API_CALL_STATE.PROCESS
        }
      }
    case API_CALL_FINISH:
      return {
        ...state,
        [action.payload.apiName]: {
          state: (HttpStatus.OK === action.payload.statusCode ? API_CALL_STATE.SUCCESS : API_CALL_STATE.FAILURE),
          statusCode: action.payload.statusCode,
          statusText: action.payload.statusText,
        }
      }
    default:
      return state
  }
}