import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import "./assets/base.scss";
import "./App.css";
import "./globalStyles.scss";

import { store } from "./store";
import { Provider } from "react-redux";
import App from "./app";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./Shared/Global/ErrorPage";

const rootElement = document.getElementById("root");

const renderApp = () => {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        {/* <ErrorPage /> */}
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <App />
        </ErrorBoundary>
      </Provider>
    </BrowserRouter>,
    rootElement
  );
};

renderApp();
