import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "../reducers";

const initialState = {};
const middlewares = applyMiddleware(thunkMiddleware);

export const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(middlewares)
);
