class StorageWrapper {
  constructor() {
    this.service = localStorage;
  }

  async save(key, value) {
    try {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      return this.service.setItem(key, value);
    } catch (err) {
      alert('Some Error Occured');
    }
  }

  delete(key) {
    try {
      return this.service.removeItem(key);
    } catch (err) {
      alert('Some Error Occured');
    }
  }

  get(key) {
    try {
      return this.service.getItem(key);
    } catch (err) {
      alert('Some Error Occured');
    }
  }

  isPresent(key) {
    try {
      return this.service.getItem(key) !== null;
    } catch (err) {
      alert('Some Error Occured');
    }
  }

  clear() {
    try {
      this.service.clear();
    } catch {}
  }

  getTenatId(key) {
    const userDetail = this.service.getItem('user');
    if (!userDetail) {
      const email = this.service.getItem('loginemail');
      if (
        email == 'naved1308.mrt@gmail.com' ||
        email == 'green.universe90@gmail.com' ||
        email == 'dav.t.vo@gmail.com'
      ) {
        return 'testing';
      } else return this.service.getItem(key);
    } else if (
      JSON.parse(userDetail).username == 'naved1308.mrt@gmail.com' ||
      JSON.parse(userDetail).username == 'green.universe90@gmail.com' ||
      JSON.parse(userDetail).username == 'dav.t.vo@gmail.com'
    )
      return 'testing';
    else return this.service.getItem(key);
  }
}

export const StorageService = new StorageWrapper();
