import {
  GET_MODULE_STATUS_SELECTION_REQUEST,
  GET_MODULE_STATUS_SELECTION_REQUEST_FAILURE,
  GET_MODULE_STATUS_SELECTION_REQUEST_SUCCESS,
  POST_MODULE_STATUS_SELECTION_REQUEST,
  POST_MODULE_STATUS_SELECTION_REQUEST_SUCCESS,
} from './actionTypes';

const selectionsinitialState = {
  //	data:{cyberRisk:"CIS,CMMC", compliance:"GDPR,PCI", additionalModules:"Activity Monitoring"},
  data: {},
  isLoading: false,
  error: undefined,
};

export const ModuleStatusSelectionsRedcuer = (
  state = selectionsinitialState,
  action,
) => {
  switch (action.type) {
    case GET_MODULE_STATUS_SELECTION_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case GET_MODULE_STATUS_SELECTION_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case GET_MODULE_STATUS_SELECTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const CreateInitialState = {
  isLoading: false,
  error: undefined,
};

export const CreateModuleAssignmentReducer = (
  state = CreateInitialState,
  action,
) => {
  const { type, data } = action;

  switch (type) {
    case POST_MODULE_STATUS_SELECTION_REQUEST: {
      return { ...state, isLoading: true };
    }
    case POST_MODULE_STATUS_SELECTION_REQUEST_SUCCESS: {
      return { ...state, isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
};
