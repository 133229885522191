
const SET_LOGIN_FIELD = 'SET_LOGIN_FIELD'

export const setLoginFieldAction = (field, value) => ({
  type: SET_LOGIN_FIELD,
  payload: {
    field,
    value,
  }
})

const initialState = {
  loginCredential: {}
}

export default function uiLoginReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_FIELD: {
      let { loginCredential } = state
      loginCredential[action.payload.field] = action.payload.value
      return {
        ...state,
        loginCredential,
      }
    }
    default:
      return state
  }
}