//reducers is a function

import {
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_REQUEST_SUCCESS,
  CREATE_ROLE_REQUEST_FAILURE,
  VIEW_ROLE_REQUEST,
  VIEW_ROLE_REQUEST_SUCCESS,
  VIEW_ROLE_REQUEST_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_REQUEST_SUCCESS,
  DELETE_ROLE_REQUEST_FAILURE,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_REQUEST_SUCCESS,
  EDIT_ROLE_REQUEST_FAILURE,
} from './actionTypes';

//VIEW

const ViewInitialState = {
  roles: [],
  isLoading: false,
  error: undefined,
  isRoleData: false,
};

export const ViewRolesReducer = (state = ViewInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case VIEW_ROLE_REQUEST: {
      return { ...state, isRoleData: true };
    }
    case VIEW_ROLE_REQUEST_SUCCESS: {
      return { ...state, roles: data, isRoleData: false };
    }
    case VIEW_ROLE_REQUEST_FAILURE: {
      const { error: msg } = action;
      return { ...state, error: msg, isRoleData: false };
    }
    default: {
      return { ...state };
    }
  }
};

const CreateInitialState = {
  roles: [],
  isLoading: false,
  error: undefined,
};

export const CreateRolesReducer = (state = CreateInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case CREATE_ROLE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case CREATE_ROLE_REQUEST_SUCCESS: {
      return { ...state, roles: data, isLoading: false };
    }
    case CREATE_ROLE_REQUEST_FAILURE: {
      const { error: msg } = action;
      return { ...state, error: msg, isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
};

const DeleteInitialState = {
  roles: [],
  isLoading: false,
  error: undefined,
};

export const DeleteRolesReducer = (state = DeleteInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case DELETE_ROLE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case DELETE_ROLE_REQUEST_SUCCESS: {
      return { ...state, roles: data, isLoading: false };
    }
    case DELETE_ROLE_REQUEST_FAILURE: {
      const { error: msg } = action;
      return { ...state, error: msg, isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
};

const EditInitialState = {
  isLoading: false,
  error: undefined,
};

export const EditRolesReducer = (state = EditInitialState, action) => {
  const { type } = action;

  switch (type) {
    case EDIT_ROLE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case EDIT_ROLE_REQUEST_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case EDIT_ROLE_REQUEST_FAILURE: {
      const { error: msg } = action;
      return { ...state, error: msg, isLoading: false };
    }
    default: {
      return { ...state };
    }
  }
};
