
const SET_CONFIGURATION_DATA = 'SET_CONFIGURATION_DATA'
const SET_CONFIGURATION_ONLY = 'SET_CONFIGURATION_ONLY'

export const SetConfigurationDataAction = configuration => ({
  type: SET_CONFIGURATION_DATA,
  payload: {
    configuration,
  }
})

export const SetConfigurationOnlyAction = config => ({
  type: SET_CONFIGURATION_ONLY,
  payload: {
    config
  }
})

const initialState = {
  configuration: {},
  industry_types: [],
  pci_dss: [],
}

export default function configurationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIGURATION_DATA:
      return action.payload.configuration
    case SET_CONFIGURATION_ONLY:
      return {
        ...state,
        configuration: action.payload.config
      }
    default:
      return state
  }
}