import React from 'react';

function index(props) {
    return (
        <div  style={{textAlign:'center',marginTop:"50px"}} className="permission">
        <h4>OOPS ! You donot have permissions to access this route.</h4>
      </div>
    );
}

export default index;