import {
	GET_EXECUTION_MODE_DETAILS_REQUEST,
	GET_EXECUTION_MODE_DETAILS_REQUEST_FAILURE,
	GET_EXECUTION_MODE_DETAILS_REQUEST_SUCCESS,
	GET_EXECUTION_MODE_SELECTION_REQUEST,
	GET_EXECUTION_MODE_SELECTION_REQUEST_FAILURE,
	GET_EXECUTION_MODE_SELECTION_REQUEST_SUCCESS,
} from './actionTypes';

const detailsinitialState = {
	data: [],
	isLoading: false,
	error: undefined,
};

export const ExecutionModeDetailsRedcuer = (
	state = detailsinitialState,
	action
) => {
	switch (action.type) {
		case GET_EXECUTION_MODE_DETAILS_REQUEST_SUCCESS: {
			const { data } = action;

			return {
				...state,
				data: data,
				isLoading: false,
			};
		}

		case GET_EXECUTION_MODE_DETAILS_REQUEST_FAILURE: {
			const { msg: error } = action;
			return {
				...state,
				error,
				isLoading: false,
			};
		}

		case GET_EXECUTION_MODE_DETAILS_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}

		default:
			return {
				...state,
			};
	}
};

const selectionsinitialState = {
	data: {},
	isLoading: false,
	error: undefined,
};

export const ExecutionModeSelectionsRedcuer = (
	state = selectionsinitialState,
	action
) => {
	switch (action.type) {
		case GET_EXECUTION_MODE_SELECTION_REQUEST_SUCCESS: {
			const { data } = action;

			return {
				...state,
				data: data,
				isLoading: false,
			};
		}

		case GET_EXECUTION_MODE_SELECTION_REQUEST_FAILURE: {
			const { msg: error } = action;
			return {
				...state,
				error,
				isLoading: false,
			};
		}

		case GET_EXECUTION_MODE_SELECTION_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}

		default:
			return {
				...state,
			};
	}
};
