
import {
  GET_BUDGET_DATA_POINTS_REQUEST,
  GET_BUDGET_DATA_POINTS_REQUEST_SUCCESS,
  GET_BUDGET_DATA_POINTS_REQUEST_FAILURE,
  GET_BUDGET_TABLE_DATA_REQUEST,
  GET_BUDGET_TABLE_DATA_REQUEST_FAILURE,
  GET_BUDGET_TABLE_DATA_REQUEST_SUCCESS,
  
} from "./actionTypes";


const initialState = {
  data: {},
  isLoading: false,
  error: undefined,
};

export const BudgetDataPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUDGET_DATA_POINTS_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case GET_BUDGET_DATA_POINTS_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case GET_BUDGET_DATA_POINTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};





const budgetTableinitialState = {
  data: [],
  isLoading: false,
  error: undefined,
};

export const BudgetTableDataReducer = (state = budgetTableinitialState, action) => {
  switch (action.type) {
    case GET_BUDGET_TABLE_DATA_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case GET_BUDGET_TABLE_DATA_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case GET_BUDGET_TABLE_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};




