import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_FAILURE,
  GET_NOTIFICATIONS_REQUEST_FROM_UPLOAD,
} from './actionTypes';

const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

export const notificationPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_NOTIFICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        notifications: [],
        loading: false,
        error: null,
      };
    case GET_NOTIFICATIONS_REQUEST_FROM_UPLOAD:
      const { data } = action;
      return {
        ...state,
        notifications: [...state.notifications.unshift(data)],
        loading: false,
        error: null,
      };
    case GET_NOTIFICATIONS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
