import {
  GET_CALIBRATION_CATEGORY_REQUEST,
  GET_CALIBRATION_CATEGORY_REQUEST_SUCCESS,
  GET_CALIBRATION_CATEGORY_REQUEST_FAILURE,
  GET_CALIBRATION_SUB_CATEGORY_REQUEST,
  GET_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE,
  GET_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS,
  GET_CALIBRATION_METRIC_REQUEST,
  GET_CALIBRATION_METRIC_REQUEST_SUCCESS,
  GET_CALIBRATION_METRIC_REQUEST_FAILURE,
  POST_CALIBRATION_CATEGORY_REQUEST_SUCCESS,
  POST_CALIBRATION_CATEGORY_REQUEST,
  POST_CALIBRATION_CATEGORY_REQUEST_FAILURE,
  POST_CALIBRATION_SUB_CATEGORY_REQUEST,
  POST_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS,
  POST_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE,
  POST_CALIBRATION_METRIC_REQUEST,
  POST_CALIBRATION_METRIC_REQUEST_SUCCESS,
  POST_CALIBRATION_METRIC_REQUEST_FAILURE,
} from './actionTypes';

const initialState = {
  data: [],
  isLoading: false,
  error: undefined,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALIBRATION_CATEGORY_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case GET_CALIBRATION_CATEGORY_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_CALIBRATION_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case POST_CALIBRATION_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case POST_CALIBRATION_CATEGORY_REQUEST_SUCCESS: {
      const { data } = action;
      console.log(data);
      return {
        ...state,
        // data: data,
        isLoading: false,
      };
    }

    case POST_CALIBRATION_CATEGORY_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
export const subCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case GET_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_CALIBRATION_SUB_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case POST_CALIBRATION_SUB_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case POST_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS: {
      const { data } = action;
      console.log(data);
      return {
        ...state,
        // data: data,
        isLoading: false,
      };
    }

    case POST_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export const metricReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CALIBRATION_METRIC_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case GET_CALIBRATION_METRIC_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_CALIBRATION_METRIC_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case POST_CALIBRATION_METRIC_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case POST_CALIBRATION_METRIC_REQUEST_SUCCESS: {
      const { data } = action;
      console.log(data);
      return {
        ...state,
        // data: data,
        isLoading: false,
      };
    }

    case POST_CALIBRATION_METRIC_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
