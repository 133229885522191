
export const API_ROOT = 'https://ocs.claribel.net/ocs/api';


const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const API_CALL_STATE = {
  UNKNOWN: 0,
  PROCESS: 1,
  SUCCESS: 2,
  FAILURE: 3,
};

const createAPI = (ID, URL, METHOD) => ({
  ID,
  URL,
  METHOD,
});

const createAPISet = (NAME, API) => {
  let result = {};
  result[`GET_${NAME}_LIST`] = createAPI(
    `GET_${NAME}_LIST`,
    `/${API}/`,
    HTTP_METHOD.GET
  );
  result[`GET_${NAME}`] = createAPI(
    `GET_${NAME}`,
    `/${API}/{0}/`,
    HTTP_METHOD.GET
  );
  result[`CREATE_${NAME}`] = createAPI(
    `CREATE_${NAME}`,
    `/${API}/`,
    HTTP_METHOD.POST
  );
  result[`UPDATE_${NAME}`] = createAPI(
    `UPDATE_${NAME}`,
    `/${API}/{0}/`,
    HTTP_METHOD.PUT
  );
  result[`DELETE_${NAME}`] = createAPI(
    `DELETE_${NAME}`,
    `/${API}/{0}/`,
    HTTP_METHOD.DELETE
  );
  return result;
};

export const API_LIST = {
  ...createAPISet('ROLE', 'roles'),
  ...createAPISet('USER', 'users'),
  ...createAPISet('CONNECTION', 'connections'),
  ...createAPISet('CONNECTION_TYPE', 'connection_types'),
  GET_USER_DATA: createAPI(
    'GET_USER_DATA',
    '/cyberRisk/auth/data/',
    HTTP_METHOD.GET
  ),
  LOGIN: createAPI('LOGIN', '/cyberRisk/auth/login/', HTTP_METHOD.POST),
  LOGOUT: createAPI('LOGOUT', '/cyberRisk/auth/logout/', HTTP_METHOD.POST),
  LOAD_CONFIGURATION: createAPI(
    'LOAD_CONFIGURATION',
    '/configuration/',
    HTTP_METHOD.GET
  ),
  SAVE_CONFIGURATION: createAPI(
    'SAVE_CONFIGURATION',
    '/configuration/',
    HTTP_METHOD.POST
  ),
  LOAD_CSV_DATA: createAPI(
    'LOAD_CSV_DATA',
    '/csv/data-point/',
    HTTP_METHOD.GET
  ),
  SAVE_CSV_DATA: createAPI('SAVE_CSV_DATA', '/csv/', HTTP_METHOD.POST),
  LOAD_CISO_DATA: createAPI(
    'LOAD_CISO_DATA',
    '/cyberRisk/ciso',
    HTTP_METHOD.POST
  ),

  //LOAD_CISO_DATA: createAPI('LOAD_CISO_DATA', '/cyberRisk/ciso', HTTP_METHOD.GET)
  

  TEST_DB_CONNECTION: createAPI(
    'TEST_DB_CONNECTION',
    '/check_db_connection/',
    HTTP_METHOD.POST
  ),
  ...createAPISet('DATAPOINT', 'data-point'),

  GET_DATAPOINT_CONNECTIONS_LIST: createAPI(
    'GET_DATAPOINT_CONNECTIONS_LIST',
    '/connections/',
    HTTP_METHOD.GET
  ),
};
