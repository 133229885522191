
const START_EDIT_CONNECTION = 'START_EDIT_CONNECTION'
const STOP_EDIT_CONNECTION = 'STOP_EDIT_CONNECTION'
const SET_EDIT_CONNECTION_FIELD = 'SET_EDIT_CONNECTION_FIELD'
const SET_CREATE_CONNECTION_FIELD = 'SET_CREATE_CONNECTION_FIELD'
const RESET_CREATE_CONNECTION = 'RESET_CREATE_CONNECTION'

export const StartEditConnectionAction = connectionInfo => ({
  type: START_EDIT_CONNECTION,
  payload: {
    connectionInfo,
  }
})

export const StopEditConnectionAction = () => ({
  type: STOP_EDIT_CONNECTION,
  payload: {},
})

export const SetEditConnectionFieldAction = (field, value) => ({
  type: SET_EDIT_CONNECTION_FIELD,
  payload: {
    field, 
    value,
  }
})

export const SetCreateConnectionFieldAction = (field, value) => ({
  type: SET_CREATE_CONNECTION_FIELD,
  payload: {
    field,
    value,
  }
})

export const ResetCreateConnectionAction = () => ({
  type: RESET_CREATE_CONNECTION,
  payload: {}
})

const initialState = {
  isEditing: false,
  editConnectionInfo: {},
  createConnectionInfo: {},
}

export default function connectionsPageReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CREATE_CONNECTION:
      return {
        ...state,
        createConnectionInfo: {},
      }
    case START_EDIT_CONNECTION:
      return {
        ...state,
        isEditing: true,
        editConnectionInfo: action.payload.connectionInfo,
      }
    case STOP_EDIT_CONNECTION:
      return {
        ...state,
        isEditing: false,
      }
    case SET_EDIT_CONNECTION_FIELD: {
      let { editConnectionInfo } = state
      editConnectionInfo[action.payload.field] = action.payload.value
      return {
        ...state,
        editConnectionInfo
      }
    }
    case SET_CREATE_CONNECTION_FIELD: {
      let { createConnectionInfo } = state
      createConnectionInfo[action.payload.field] = action.payload.value
      return {
        ...state,
        createConnectionInfo,
      }
    }
    default:
      return state
  }
}