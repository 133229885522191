export const GET_BUDGET_REQUEST = 'GET_BUDGET_REQUEST';
export const GET_BUDGET_REQUEST_SUCCESS = 'GET_BUDGET_REQEUST_SUCCESS';
export const GET_BUDGET_REQUEST_FAILURE = 'GET_BUDGET_REQEUST_FAILURE';

export const GET_BUDGET_SUMMARY_REQUEST = 'GET_BUDGET_SUMMARY_REQUEST';
export const GET_BUDGET_SUMMARY_REQUEST_SUCCESS =
	'GET_BUDGET_SUMMARY_REQEUST_SUCCESS';
export const GET_BUDGET_SUMMARY_REQUEST_FAILURE =
	'GET_BUDGET_SUMMARY_REQEUST_FAILURE';

export const GET_BUDGET_PROGRAM_SUMMARY_REQUEST =
	'GET_BUDGET_PROGRAM_SUMMARY_REQUEST';
export const GET_BUDGET_PROGRAM_SUMMARY_REQUEST_SUCCESS =
	'GET_BUDGET_PROGRAM_SUMMARY_REQEUST_SUCCESS';
export const GET_BUDGET_PROGRAM_SUMMARY_REQUEST_FAILURE =
	'GET_BUDGET_PROGRAM_SUMMARY_REQEUST_FAILURE';
