
const SET_CISO_ACTIVE_TAB = 'SET_CISO_ACTIVE_TAB'
const SET_CISO_CATEGORY = 'SET_CISO_CATEGORY'
const SET_CISO_SUB_CATEGORY = 'SET_CISO_SUB_CATEGORY'
const SET_CISO_COLLAPSE_STATE = 'SET_CISO_COLLAPSE_STATE'

export const setCisoActiveTabAction = tab => ({
  type: SET_CISO_ACTIVE_TAB,
  payload: { tab }
})

export const setCisoCategoryAction = id => ({
  type: SET_CISO_CATEGORY,
  payload: { id }
})

export const setCisoSubCategoryAction = id => ({
  type: SET_CISO_SUB_CATEGORY,
  payload: { id }
})

export const setCisoCollapseState = (name, state) => ({
  type: SET_CISO_COLLAPSE_STATE,
  payload: { name, state }
})

const initialState = {
  activeTab: '1',
  selCategory: undefined,
  selSubCategory: undefined,
  collapseState: {},
}

export default function uiCisoManagementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CISO_COLLAPSE_STATE: {
      let { collapseState } = state
      collapseState[action.payload.name] = action.payload.state
      return {
        ...state,
        collapseState,
      }
    }
    case SET_CISO_CATEGORY:
      return {
        ...state,
        selCategory: action.payload.id
      }
    case SET_CISO_SUB_CATEGORY:
      return {
        ...state,
        selSubCategory: action.payload.id
      }
    case SET_CISO_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.tab
      }
    default:
      return state
  }
}