import {
    CREATE_JIRA_TICKET_REQUEST,
    CREATE_JIRA_TICKET_REQUEST_SUCCESS,
    CREATE_JIRA_TICKET_REQUEST_FAILURE,
    RESET_TICKET_STATE,
  } from "./actionTypes";
  
  const initialState = {
    loading: false,
    ticket: null,
    error: null,
  };
  
  const jiraTicketReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_JIRA_TICKET_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case CREATE_JIRA_TICKET_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          ticket: action.payload,
        };
  
      case CREATE_JIRA_TICKET_REQUEST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case RESET_TICKET_STATE: // Reset ticket and error
        return { ...initialState };
      default:
        return state;
    }
  };
  
  export default jiraTicketReducer;
  