
const SET_CISO_DATA = 'SET_CISO_DATA'

export const setCisoDataAction = data => ({
  type: SET_CISO_DATA,
  payload: { data }
})

const initialState = {
  cisoData: undefined
}

export default function entityCisoReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CISO_DATA:
      return {
        ...state,
        cisoData: action.payload.data
      }
    default:
      return state
  }
}