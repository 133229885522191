import {
    GET_RISKS_REQUEST,
    GET_RISKS_REQUEST_SUCCESS,
    GET_RISKS_REQUEST_FAILURE,
    UPDATE_RISK_REQUEST,
    UPDATE_RISK_REQUEST_SUCCESS,
    UPDATE_RISK_REQUEST_FAILURE,
    GET_LIKELIHOODTYPES_REQUEST,
    GET_LIKELIHOODTYPES_REQUEST_SUCCESS,
    GET_LIKELIHOODTYPES_REQUEST_FAILURE,
    GET_IMPACTTYPES_REQUEST,
    GET_IMPACTTYPES_REQUEST_SUCCESS,
    GET_IMPACTTYPES_REQUEST_FAILURE
  } from './actionTypes';
  
  const initialState = {
    risks: [],
    likelihoodTypes: [],
    impactTypes: [],
    loading: false,
    error: null
  };
  
  const riskManagementReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_RISKS_REQUEST:
      case UPDATE_RISK_REQUEST:
      case GET_LIKELIHOODTYPES_REQUEST:
      case GET_IMPACTTYPES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case GET_RISKS_REQUEST_SUCCESS:
        return {
          ...state,
          risks: action.payload,
          loading: false
        };
      case UPDATE_RISK_REQUEST_SUCCESS:
        return {
          ...state,
          risks: state.risks.map(risk =>
            risk.id === action.payload.id ? action.payload : risk
          ),
          loading: false
        };
      case GET_LIKELIHOODTYPES_REQUEST_SUCCESS:
        return {
          ...state,
          likelihoodTypes: action.payload,
          loading: false
        };
      case GET_IMPACTTYPES_REQUEST_SUCCESS:
        return {
          ...state,
          impactTypes: action.payload,
          loading: false
        };
      case GET_RISKS_REQUEST_FAILURE:
      case UPDATE_RISK_REQUEST_FAILURE:
      case GET_LIKELIHOODTYPES_REQUEST_FAILURE:
      case GET_IMPACTTYPES_REQUEST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default riskManagementReducer;
  