import { getNormalizedData, mergeNormalizedData } from '../../utils/service';

const SET_ROLE_DATA = 'SET_ROLE_DATA';
const ADD_OR_UPDATE_DATA = 'ADD_OR_UPDATE_DATA';
const DELETE_ROLE_DATA = 'DELETE_ROLE_DATA';

export const SetRoleDataAction = (roleList) => ({
  type: SET_ROLE_DATA,
  payload: {
    roleList,
  },
});

export const AddOrUpdateRoleDataAction = (roleList) => ({
  type: ADD_OR_UPDATE_DATA,
  payload: {
    roleList,
  },
});

export const DeleteRoleDataAction = (id) => ({
  type: DELETE_ROLE_DATA,
  payload: {
    id,
  },
});

const initialState = getNormalizedData([]);

export default function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ROLE_DATA:
      state = getNormalizedData(action.payload.roleList);
      return state;
    case ADD_OR_UPDATE_DATA: {
      let normData = getNormalizedData(action.payload.roleList);
      let newState = mergeNormalizedData(state, normData);
      return newState;
    }
    case DELETE_ROLE_DATA: {
      let id = action.payload.id;
      let newState = JSON.parse(JSON.stringify(state));
      newState.ids = newState.ids.filter((x) => x !== id);
      delete newState.byId[id];
      return newState;
    }
    default:
      return state;
  }
}
