import {
  GET_INSIDER_THREAT_REQUEST,
  GET_INSIDER_THREAT_REQUEST_FAILURE,
  GET_INSIDER_THREAT_REQUEST_SUCCESS
} from "./actionTypes";

const initialState = {
  insiderThreatData: undefined,
  isLoading: false,
  error: undefined,
};

export const insiderThreatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSIDER_THREAT_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        insiderThreatData: data,
        isLoading: false,
      };
    }
    case GET_INSIDER_THREAT_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_INSIDER_THREAT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
