import axios from 'axios';

const LOCAL_STORAGE_CONST = {
  ACCESS_TOKEN: "accessToken",
  ROLE: 'role',
}

const ACCOUNT_REFRESH_BY_ACCESS_TOKEN = 'ACCOUNT_REFRESH_BY_ACCESS_TOKEN'
const ACCOUNT_LOGIN = 'ACCOUNT_LOGIN'
const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'

export const accountLoginAction = (accessToken, accountData, accountRole) => ({
  type: ACCOUNT_LOGIN,
  payload: { accessToken, accountData, accountRole }
})

export const accountRefreshByAccessTokenAction = (accountData) => ({
  type: ACCOUNT_REFRESH_BY_ACCESS_TOKEN,
  payload: { accountData }
})

export const accountLogoutAction = () => ({
  type: ACCOUNT_LOGOUT,
})


const loadAccessToken = () => {
  let accessToken = localStorage.getItem(LOCAL_STORAGE_CONST.ACCESS_TOKEN)
  if (null !== accessToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`
  } else {
    accessToken = undefined
  }

  return accessToken
}

const saveAccessToken = accessToken => {
  if (undefined !== accessToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`
    localStorage.setItem(LOCAL_STORAGE_CONST.ACCESS_TOKEN, accessToken)
  } else {
    axios.defaults.headers.common['Authorization'] = undefined
    localStorage.removeItem(LOCAL_STORAGE_CONST.ACCESS_TOKEN)
  }
}

const loadRole = () => {
  let role = localStorage.getItem(LOCAL_STORAGE_CONST.ROLE)
  return null === role ? undefined : parseInt(role)
}

const saveRole = role => {
  if (undefined !== role) {
    localStorage.setItem(LOCAL_STORAGE_CONST.ROLE, role)
  } else {
    localStorage.removeItem(LOCAL_STORAGE_CONST.ROLE)
  }
}

const initialState = {
  accessToken: loadAccessToken(),
  role: loadRole(),
  isAuthenticated: false,
  accountData: {},
}

export default function entityAccountReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LOGIN: {
      saveAccessToken(action.payload.accessToken)
      saveRole(action.payload.accountRole)
      return {
        ...state,
        accessToken: action.payload.accessToken,
        accountData: action.payload.accountData,
        role: action.payload.accountRole,
        isAuthenticated: true,
      }
    }
    case ACCOUNT_LOGOUT: {
      saveAccessToken(undefined)
      saveRole(undefined)
      return {
        ...state,
        accessToken: undefined,
        role: undefined,
        accountData: {},
        isAuthenticated: false,
      }
    }
    case ACCOUNT_REFRESH_BY_ACCESS_TOKEN: {
      return {
        ...state,
        accountData: action.payload.accountData,
        isAuthenticated: true,
      }
    }
    default:
      return state
  }
}