export const DOWNLOAD_CYBER_CSV_REQUEST = 'DOWNLOAD_CYBER_CSV_REQUEST';
export const DOWNLOAD_CYBER_CSV_REQUEST_SUCCESS =
  'DOWNLOAD_CYBER_CSV_REQUEST_SUCCESS';
export const DOWNLOAD_CYBER_CSV_REQUEST_FAILURE =
  'DOWNLOAD_CYBER_CSV_REQUEST_FAILURE';

export const UPLOAD_CYBER_CSV_REQUEST = 'UPLOAD_CYBER_CSV_REQUEST';
export const UPLOAD_CYBER_CSV_REQUEST_SUCCESS =
  'UPLOAD_CYBER_CSV_REQUEST_SUCCESS';
export const UPLOAD_CYBER_CSV_REQUEST_FAILURE =
  'UPLOAD_CYBER_CSV_REQUEST_FAILURE';
