//reducers is a function 

import {CREATE_CLIENT_REQUEST,CREATE_CLIENT_REQUEST_SUCCESS,CREATE_CLIENT_REQUEST_FAILURE,

    VIEW_CLIENT_REQUEST,VIEW_CLIENT_REQUEST_SUCCESS,VIEW_CLIENT_REQUEST_FAILURE,

    DELETE_CLIENT_REQUEST,DELETE_CLIENT_REQUEST_SUCCESS,DELETE_CLIENT_REQUEST_FAILURE,

     EDIT_CLIENT_REQUEST,EDIT_CLIENT_REQUEST_SUCCESS,EDIT_CLIENT_REQUEST_FAILURE,
} from './actionTypes'



//VIEW 

const ViewInitialState = {
  clients: [],
  isLoading: false,
  error: undefined,
};


export const ViewClientsReducer=(state=ViewInitialState,action)=>
{
    const {type,data}=action;

    switch(type) {
  case VIEW_CLIENT_REQUEST:{
      return {...state,isLoading:true};
  }
  case VIEW_CLIENT_REQUEST_SUCCESS:{
      return{...state,clients:data,isLoading:false};
  }
  case VIEW_CLIENT_REQUEST_FAILURE:{
    const {error:msg}=action;
      return {...state,error:msg,isLoading:false};
  }
  default:
      return {...state};
}

}



const CreateInitialState = {
  clients: [],
  isLoading: false,
  error: undefined,
};


export const CreateClientsReducer=(state=CreateInitialState,action)=>
{
    const {type,data}=action;

    switch(type) {
  case CREATE_CLIENT_REQUEST:
      return {...state,isLoading:true};
  case CREATE_CLIENT_REQUEST_SUCCESS:
      return{...state,clients:data,isLoading:false};
  case CREATE_CLIENT_REQUEST_FAILURE:
      const {error:msg}=action;
      return {...state,error:msg,isLoading:false};
  default:
      return {...state};
}

}





const DeleteInitialState = {
  clients: [],
  isLoading: false,
  error: undefined,
};


export const DeleteClientsReducer=(state=DeleteInitialState,action)=>
{
    const {type,data}=action;

    switch(type) {
  case DELETE_CLIENT_REQUEST:
      return {...state,isLoading:true};
  case DELETE_CLIENT_REQUEST_SUCCESS:
      return{...state,clients:data,isLoading:false};
  case DELETE_CLIENT_REQUEST_FAILURE:
        const {error:msg}=action;
      return {...state,error:msg,isLoading:false};
  default:
      return {...state};
}

}



const EditInitialState = {
  clients: [],
  isLoading: false,
  error: undefined,
};


export const EditClientsReducer=(state=EditInitialState,action)=>
{
    const {type,data}=action;

    switch(type) {
  case EDIT_CLIENT_REQUEST:
      return {...state,isLoading:true};
  case EDIT_CLIENT_REQUEST_SUCCESS:
      return{...state,isLoading:false};
  case EDIT_CLIENT_REQUEST_FAILURE:
       const {error:msg}=action;
      return {...state,error:msg,isLoading:false};
  default:
      return {...state};
}

}



