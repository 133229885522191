import { AuthApi } from "../../services/api.service";
import { StorageService } from "../../services/storageService";
import { ORG_NAME } from "../../../src/utils/const";
import {
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_REQUEST_SUCCESS,
  VALIDATE_OTP_REQUEST_FAILURE,
  SEND_OTP_REQUEST,
  SEND_OTP_REQUEST_SUCCESS,
  SEND_OTP_REQUEST_FAILURE,
  SIGININ_REQUEST,
  SIGININ_REQUEST_SUCCESS,
  SIGININ_REQUEST_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_REQUEST_SUCCESS,
  SIGNUP_REQUEST_FAILURE,
  GETUSER_REQUEST,
  GETUSER_REQUEST_SUCCESS,
  GENERATE_TOKEN_REQUEST,
  GENERATE_TOKEN_REQUEST_SUCCESS,
  GENERATE_TOKEN_REQUEST_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
} from "./actionTypes";
import { getDefaultRole } from "../../utils/utility";

import { showError, showInformation, showSuccess } from "../../utils/service";
import { setModuleAssigned } from "../moduleAssigned/actions";

export const SendOtp = (user) => {
  return (dispatch) => {
    dispatch({
      type: SEND_OTP_REQUEST,
    });

    AuthApi.post("/mail/forgot-password", { email: user.username })
      .then(({ status }) => {
        if (status === 200) {
          showSuccess("Credentials Validated, Sending OTP");
          dispatch({
            type: SEND_OTP_REQUEST_SUCCESS,
            data: { email: user.username },
          });
        } else if (status === 401) {
          StorageService.clear();
          showError("Invalid Credentials");
          dispatch(Logout());
        } else {
          StorageService.clear();
          showError("Invalid Credentials");
        }
      })
      .catch((err) => {
        StorageService.clear();
        dispatch({
          type: SEND_OTP_REQUEST_FAILURE,
          msg: err.message,
        });
      });
  };
};

export const SignIn = (user) => {
  return (dispatch) => {
    dispatch({
      type: SIGININ_REQUEST,
    });

    AuthApi.post("/auth/signIn", user)
      .then(({ status }) => {
        if (status === 200) {
          showSuccess("Credentials Validated, Sending OTP");
          // const userData = { ...user, "otp":"" };
          //  dispatch(GenerateToken(userData) )
          dispatch({
            type: SIGININ_REQUEST_SUCCESS,
            data: user,
          });
        } else if (status === 401) {
          StorageService.clear();
          showError("Invalid Credentials");
          dispatch(Logout());
        } else {
          StorageService.clear();
          showError("Invalid Credentials");
        }
      })
      .catch((err) => {
        StorageService.clear();
        dispatch({
          type: SIGININ_REQUEST_FAILURE,
          msg: err.message,
        });
      });
  };
};
export const SignUp = (user, otp, cb) => {
  return (dispatch) => {
    dispatch({
      type: SIGNUP_REQUEST,
    });
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      organization: user.organization,
      role: user.role,
      email: user.email,
      otp: user.otp,
      password: user.password,
      confirmpassword: user.confirmpassword,
    };
    AuthApi.post("/auth/signup", data)
      .then(({ status }) => {
        if (status === 200) {
          showSuccess("Credentials Validated, Sending TEMPORARYPASSWORD");
          dispatch({
            type: SIGNUP_REQUEST_SUCCESS,
            data,
          });
        } else if (status === 400) {
          StorageService.clear();
          showError("Invalid Credentialsssss");
          dispatch(Logout());
        } else {
          cb && cb();
          showError("Incorrect OTP. Please try again");
          // StorageService.clear();
        }
      })
      .catch((err) => {
        cb && cb();
        StorageService.clear();
        dispatch({
          type: SIGNUP_REQUEST_FAILURE,
          msg: err.message,
        });
      });
  };
};

export const ValidateOTP = (user, otp, cb) => {
  return (dispatch) => {
    dispatch({
      type: VALIDATE_OTP_REQUEST,
    });
    const data = {
      username: user.username,
      password: user.password,
      otp: user.otp,
    };
    AuthApi.post("/otp/validate", data, true)
      .then(({ data }) => {
        if (data === true) {
          showSuccess("Successfully Verified");
          dispatch({
            type: VALIDATE_OTP_REQUEST_SUCCESS,
            data,
          });
          dispatch(GenerateToken(user));
          // dispatch(getCurrentUSerDetails());
        } else {
          cb && cb();
          showError("Incorrect OTP. Please try again");
        }
      })
      .catch((err) => {
        cb && cb();
        StorageService.clear();
        dispatch({
          type: VALIDATE_OTP_REQUEST_FAILURE,
          msg: err.message,
        });

        showError("Something went wrong");

        dispatch({
          type: SIGININ_REQUEST_FAILURE,
          data: null,
        });

        showError("Something went wrong");
      });
  };
};

export const getCurrentUSerDetails = () => {
  return (dispatch) => {
    dispatch({
      type: GETUSER_REQUEST,
    });

    AuthApi.get("/user/me").then(({ data, status }) => {
      if (status === 403) {
        showInformation("Session Expired, Please Login Again");
        return dispatch(Logout());
      }
      if (data.firstLogin === true) {
        // dispatch({
        //   type: RESET_PASSWORD_REQUEST,
        //   data: data,
        // });
        dispatch({
          type: GETUSER_REQUEST_SUCCESS,
          data: data,
        });
        dispatch(redirectToDefaultRole(data));
        dispatch(setModuleAssigned(data["moduleAssigned"]));
        showInformation(
          "Thank you for login. Your account will be activated in 24 hours."
        );
      } else {
        dispatch(redirectToDefaultRole(data));
        dispatch({
          type: GETUSER_REQUEST_SUCCESS,
          data: data,
        });
        dispatch(setModuleAssigned(data["moduleAssigned"]));
      }
      const currentActiveRole = localStorage.getItem("currentActive");
      // Find the role where isDefault is true
      let defaultRoleName = null;
      if (!currentActiveRole || currentActiveRole == null) {
        for (const role of data.roles) {
          if (role.isDefault) {
            defaultRoleName = role.name;
            break;
          }
        }
        if (defaultRoleName == "CISO") {
          localStorage.setItem("currentActive", "Executive");
        } else if (defaultRoleName == "Operations") {
          localStorage.setItem("currentActive", "Configuration Manager");
        } else {
          localStorage.setItem("currentActive", defaultRoleName);
        }
      }
      StorageService.save("roles", data.roles);
    });
  };
};

export const redirectToDefaultRole = (User) => {
  return (dispatch) => {
    getDefaultRole(User)
      .then((defaultRole) => {
        dispatch({
          type: SIGININ_REQUEST_SUCCESS,
          data: { ...User, defaultRole: defaultRole },
        });
        dispatch({
          type: SIGNUP_REQUEST_SUCCESS,
          data: { ...User, defaultRole: defaultRole },
        });
      })
      .catch((err) => {
        showError("Error in fetching user details");
        showInformation("Redirecting to Login");
        dispatch(Logout());
      });
  };
};

export const Logout = (User) => {
  return (dispatch) => {
    StorageService.delete(ORG_NAME);
    StorageService.delete("user");
    StorageService.delete("token");
    dispatch({
      type: SIGININ_REQUEST_SUCCESS,
      data: {},
    });
    dispatch({
      type: SIGNUP_REQUEST_SUCCESS,
      data: {},
    });
  };
};

export const ResetPassword = (user) => {
  return (dispatch) => {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });
    const data = {
      newPassword: user.confirmNewPass,
      password: user.newPass,
      userId: user.userId,
    };

    AuthApi.post("/auth/change-password", data, true)
      .then(({ data, status }) => {
        switch (status) {
          case 200: {
            dispatch({
              type: RESET_PASSWORD_REQUEST_SUCCESS,
              data,
            });
            showSuccess(data);
            dispatch(Logout());
            break;
          }
          default: {
            showInformation(data.message);
            dispatch({
              type: RESET_PASSWORD_REQUEST_FAILURE,
              msg: "",
            });
          }
        }

        // dispatch(Logout());
        // StorageService.save('token', data).then(() => {

        // 	dispatch({
        // 		type: RESET_PASSWORD_REQUEST_SUCCESS,
        // 		data,
        // 	});
        // 	showSuccess('Password has been reset');
        // 	StorageService.save('user', {});//clearing store data to be logged in again with new password
        // 	dispatch(Logout());
        // });
      })
      .catch((err) => {
        showError(err.message);
        dispatch({
          type: RESET_PASSWORD_REQUEST_FAILURE,
          msg: err.message,
        });
        dispatch({
          type: SIGININ_REQUEST_FAILURE,
          data: null,
        });
      });
  };
};
export const GenerateToken = (user) => {
  return (dispatch) => {
    dispatch({
      type: GENERATE_TOKEN_REQUEST,
    });

    AuthApi.post("/auth/token", user, true)
      .then(({ data }) => {
        StorageService.save("token", data).then(() => {
          dispatch({
            type: GENERATE_TOKEN_REQUEST_SUCCESS,
            data,
          });
          StorageService.save("user", user);
          //  dispatch(GenerateOtp(user));
          dispatch(getCurrentUSerDetails());
        });
      })
      .catch((err) => {
        StorageService.clear();
        dispatch({
          type: GENERATE_TOKEN_REQUEST_FAILURE,
          msg: err.message,
        });

        showError("Something went wrong");

        dispatch({
          type: SIGININ_REQUEST_FAILURE,
          data: null,
        });

        showError("Something went wrong");
      });
  };
};
