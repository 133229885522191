import axios from "axios";
import { Logout } from "../reducers/Auth/actions";
import { store } from "../store";
import { ORG_NAME } from "../utils/const";
import { showInformation } from "../utils/service";
import { getApiUrl } from "../utils/utility";
import { StorageService } from "./storageService";

class ApiWrapper {
  constructor(options) {
    let headers = {
      "Content-Type": "application/json",
      // 'X-Tenant-Id' : StorageService.get(ORG_NAME),
    };

    const optHeaders = options || {};
    headers = { ...headers, ...optHeaders };

    const defaultOptions = {
      headers: headers,
      timeout: 240000,
      validateStatus: () => true,
    };

    const serviceOptions = { ...defaultOptions, ...options };
    this.service = axios.create(serviceOptions);
  }

  get(api, params = {}, request) {
    try {
      const url = getApiUrl(api, params, this.service.defaults.baseURL);
      let config = {};
      if (request && request.headers && request.headers.cookie) {
        config["headers"] = {
          ...config["headers"],
          Cookie: require.headers.cookie,
        };
      }

      return this.service.get(url, config);
    } catch (err) {}
  }

  put(api, params = {}, payload) {
    const url = getApiUrl(api, params, this.service.defaults.baseURL);
    let config = {};
    return this.service.request({
      method: "PUT",
      url,
      config,
      responseType: "json",
      data: payload,
    });
  }

  post(api, payload, plain = false) {
    let config = {};
    try {
      const url = getApiUrl(api, [], this.service.defaults.baseURL);
      return this.service.request({
        method: "POST",
        url,
        config,
        responseType: plain ? "text" : "json",
        data: payload,
      });
    } catch (err) {}
  }

  delete(api, payload) {
    try {
      const url = getApiUrl(api, [], this.service.defaults.baseURL);
      let config = {};
      return this.service.request({
        method: "DELETE",
        url,
        config,
        responseType: "json",
        data: payload,
      });
    } catch (err) {}
  }

  download(api) {
    try {
      const url = getApiUrl(api, []);
      return this.service.request({
        method: "GET",
        url,
        responseType: "blob",
      });
    } catch (err) {}
  }

  upload(api, file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const url = getApiUrl(api);

      return this.service.request({
        method: "POST",
        url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (err) {}
  }
}

export const Api = new ApiWrapper();

export const AuthApi = new ApiWrapper({
  baseURL: "https://ocs.claribel.net/claribel-auth/api",
});

Api.service.interceptors.request.use(
  (config) => {
    config.headers.common["X-Tenant-Id"] = StorageService.getTenatId(ORG_NAME);
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(Logout());
    }

    return Promise.reject(error);
  }
);

AuthApi.service.interceptors.request.use(
  (config) => {
    config.headers.common["Authorization"] = `Bearer ${StorageService.get(
      "token"
    )}`;
    config.headers.common["X-Tenant-Id"] = StorageService.getTenatId(ORG_NAME);
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(Logout());
    }
    return Promise.reject(error);
  }
);

AuthApi.service.interceptors.response.use(
  (config) => {
    if (config.status === 401) {
      store.dispatch(Logout());
    }
    if (config.status === 403) {
      showInformation("Session Expired, Please Login Again");
      store.dispatch(Logout());
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
