
const SET_CSV_DATA = 'SET_CSV_DATA'

export const SetCsvDataAction = csvData => ({
  type: SET_CSV_DATA,
  payload: {
    csvData,
  }
})

const initialState = {
  csvData: [],
}

export default function CsvDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CSV_DATA:
      return {
        ...state,
        csvData: action.payload.csvData
      }
    default:
      return state
  }
}