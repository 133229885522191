import {
  GET_CYBER_DATA_POINTS_REQUEST,
  GET_CYBER_DATA_POINTS_REQUEST_SUCCESS,
  GET_CYBER_DATA_POINTS_REQUEST_FAILURE,
  GET_CYBER_TABLE_DATA_REQUEST,
  GET_CYBER_TABLE_DATA_REQUEST_FAILURE,
  GET_CYBER_TABLE_DATA_REQUEST_SUCCESS,
  UPDATE_DATA_POINTS_REQUEST,
  UPDATE_DATA_POINTS_REQUEST_SUCCESS,
  UPDATE_DATA_POINTS_REQUEST_FAILURE,
  UPDATE_DATAPOINTSDENOMINATOR_DATA,
  UPDATE_DATAPOINTSNUMERATOR_DATA,
  UPDATE_DATAPOINTS_DATA,
} from './actionTypes';

const initialState = {
  data: {},
  isLoading: false,
  error: undefined,
};

export const CyberDataPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CYBER_DATA_POINTS_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case GET_CYBER_DATA_POINTS_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case GET_CYBER_DATA_POINTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const cyberTableinitialState = {
  data: [],
  isLoading: false,
  error: undefined,
  isCyberDataPoint: false,
};

export const CyberTableDataReducer = (
  state = cyberTableinitialState,
  action,
) => {
  switch (action.type) {
    case GET_CYBER_TABLE_DATA_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
        isCyberDataPoint: false,
      };
    }

    case GET_CYBER_TABLE_DATA_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
        isCyberDataPoint: false,
      };
    }

    case GET_CYBER_TABLE_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isCyberDataPoint: true,
      };
    }
    case UPDATE_DATAPOINTSDENOMINATOR_DATA: {
      const { data } = action;
      return {
        ...state,
        data: {
          ...state.data,
          dataPointsDenominator: data,
        },
      };
    }
    case UPDATE_DATAPOINTS_DATA: {
      const { data } = action;
      return {
        ...state,
        data: {
          ...state.data,
          dataPointData: { ...state.data.dataPointData, ...data },
        },
      };
    }
    case UPDATE_DATAPOINTSNUMERATOR_DATA: {
      const { data } = action;

      return {
        ...state,
        data: {
          ...state.data,
          dataPointsNumerator: data,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const dataPointInitialsState = {
  data: [],
  isLoading: false,
  error: undefined,
};

export const UpdateDataPointsReducer = (
  state = dataPointInitialsState,
  action,
) => {
  switch (action.type) {
    case UPDATE_DATA_POINTS_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case UPDATE_DATA_POINTS_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case UPDATE_DATA_POINTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
