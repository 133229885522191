import { moduleAssignmentMapping } from "../../constants";
import { showError } from "../../utils/service";
import { Logout } from "../Auth/actions";
import {
  SET_BUDGET_AND_PROGRAM_MODULE,
  SET_COMPLIANCE_MODULE,
  SET_CYBER_RISK_MODULE,
  SET_INSIDER_THREAT_MODULE,
  SET_REPORTS_MODULE,
} from "./actionTypes";

export const setModuleAssigned = (data) => (dispatch) => {
  if (!data) {
    dispatch(Logout());

    return showError("No Module Assigned to user yet");
  }

  Object.entries(data)?.forEach(([item, value]) => {
    if (item === moduleAssignmentMapping["Cyber Risk"]) {
      dispatch({
        type: SET_CYBER_RISK_MODULE,
        data: value,
      });
    } else if (item === moduleAssignmentMapping["Compliance"]) {
      dispatch({
        type: SET_COMPLIANCE_MODULE,
        data: value,
      });
    } else if (item === moduleAssignmentMapping["Budget Program"]) {
      dispatch({
        type: SET_BUDGET_AND_PROGRAM_MODULE,
        data: value,
      });
    } else if (item === moduleAssignmentMapping["Insider Threat"]) {
      dispatch({
        type: SET_INSIDER_THREAT_MODULE,
        data: value,
      });
    } else if (item === moduleAssignmentMapping["reports"]) {
      dispatch({
        type: SET_REPORTS_MODULE,
        data: value,
      });
    }
  });
};
