export const GET_CONNECTIONS_REQUEST = 'GET_CONNECTIONS_REQUEST';
export const GET_CONNECTIONS_REQUEST_SUCCESS = 'GET_CONNECTIONS_REQEUST_SUCCESS';
export const GET_CONNECTIONS_REQUEST_FAILURE = 'GET_CONNECTIONS_REQEUST_FAILURE';


export const  CREATE_CONNECTION_REQUEST = 'CREATE_CONNECTION_REQUEST';
export const CREATE_CONNECTION_REQUEST_SUCCESS = 'CREATE_CONNECTION_REQEUST_SUCCESS';
export const CREATE_CONNECTION_REQUEST_FAILURE = 'CREATE_CONNECTION_REQEUST_FAILURE';



export const  TEST_CONNECTION_REQUEST = 'TEST_CONNECTION_REQUEST';
export const TEST_CONNECTION_REQUEST_SUCCESS = 'TEST_CONNECTION_REQEUST_SUCCESS';
export const TEST_CONNECTION_REQUEST_FAILURE = 'TEST_CONNECTION_REQEUST_FAILURE';





export const  DELETE_CONNECTION_REQUEST = 'DELETE_CONNECTION_REQUEST';
export const DELETE_CONNECTION_REQUEST_SUCCESS = 'DELETE_CONNECTION_REQEUST_SUCCESS';
export const DELETE_CONNECTION_REQUEST_FAILURE = 'DELETE_CONNECTION_REQEUST_FAILURE';




export const  UPDATE_CONNECTION_REQUEST = 'UPDATE_CONNECTION_REQUEST';
export const UPDATE_CONNECTION_REQUEST_SUCCESS = 'UPDATE_CONNECTION_REQEUST_SUCCESS';
export const UPDATE_CONNECTION_REQUEST_FAILURE = 'UPDATE_CONNECTION_REQEUST_FAILURE';


