import { CHANGE_CURRENT_COMPANY, TOGGLE_SWITCH_COMPANY } from './actionTypes';

const initialState = {
    isOpen: false,
    currentActiveCompany: undefined,
};

export const switchCompany = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SWITCH_COMPANY: {
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        }
        case CHANGE_CURRENT_COMPANY: {
            const { role } = action;
            return {
                ...state,
                currentActiveCompany: role,
            };
        }
        default:
            return {
                ...state,
            };
    }
};