const SET_CREATE_DATAPOINT = 'SET_CREATE_DATAPOINT';
const RESET_CREATE_DATAPOINT_INFO = 'RESET_CREATE_DATAPOINT_INFO';
const SET_EDIT_DATAPOINT = 'SET_EDIT_DATAPOINT';

export function SetCreateDataPointAction(field, value) {
  return {
    type: SET_CREATE_DATAPOINT,
    payload: {
      field,
      value,
    },
  };
}

export const EditDatapoint = (datapointObj) => {
  return {
    type: SET_EDIT_DATAPOINT,
    payload: {
      datapointObj,
    },
  };
};

export const ResetCreateDatapointInfo = () => ({
  type: RESET_CREATE_DATAPOINT_INFO,
  payload: {},
});

const initialState = {
  showCustomFrequency: false,
  showQueryFrequency: true,
  createDatapointInfo: {
    mode: 'A',
    frequency: 'daily',
    custom_frequency: '',
  },
  isEdit: false,
};

export default function datapointPageReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CREATE_DATAPOINT_INFO:
      let createDatapointInfoReset = {
        mode: 'A',
        frequency: 'daily',
        connectionTypeId: '',
      };
      return {
        ...state,
        createDatapointInfo: createDatapointInfoReset,
        isEdit: false,
        showCustomFrequency: false,
        showQueryFrequency: true,
      };
    case SET_EDIT_DATAPOINT: {
      let { showCustomFrequency, showQueryFrequency } = state;
      if (action.payload.datapointObj.mode === 'M') {
        showCustomFrequency = false;
        showQueryFrequency = false;
      } else {
        showQueryFrequency = true;
      }
      if (
        !['daily', 'monthly', 'yearly'].includes(
          action.payload.datapointObj.frequency
        )
      ) {
        showCustomFrequency = true;
      } else {
        showCustomFrequency = false;
      }
      return {
        ...state,
        createDatapointInfo: {
          ...state.createDatapointInfo,
          ...action.payload.datapointObj,
        },
        isEdit: true,
        showCustomFrequency,
        showQueryFrequency,
      };
    }

    case SET_CREATE_DATAPOINT:
      let { createDatapointInfo } = state;
      if (action.payload.field === 'custom_frequency') {
        createDatapointInfo['frequency'] = action.payload.value;
      } else if (
        action.payload.field === 'mode' &&
        action.payload.value === 'M'
      ) {
        createDatapointInfo['frequency'] = '';
        createDatapointInfo['mode'] = 'M';
      } else if (
        action.payload.field === 'frequency' &&
        action.payload.value === 'custom'
      ) {
        createDatapointInfo['frequency'] =
          createDatapointInfo['custom_frequency'];
      } else {
        createDatapointInfo[action.payload.field] = action.payload.value;
      }
      return {
        ...state,
        createDatapointInfo,
        showCustomFrequency:
          action.payload.field === 'frequency'
            ? action.payload.value === 'custom'
              ? true
              : false
            : state.showCustomFrequency,
        showQueryFrequency:
          action.payload.field === 'mode'
            ? action.payload.value === 'A'
              ? true
              : false
            : state.showQueryFrequency,
      };
    default:
      return state;
  }
}
