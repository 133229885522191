export const CREATE_CLIENT_REQUEST="CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_REQUEST_SUCCESS="CREATE_CLIENT_REQUEST_SUCCESS";
export const CREATE_CLIENT_REQUEST_FAILURE="CREATE_CLIENT_REQUEST_FAILURE";



export const VIEW_CLIENT_REQUEST="VIEW_CLIENT_REQUEST";
export const VIEW_CLIENT_REQUEST_SUCCESS="VIEW_CLIENT_REQUEST_SUCCESS";
export const VIEW_CLIENT_REQUEST_FAILURE="VIEW_CLIENT_REQUEST_FAILURE";




export const DELETE_CLIENT_REQUEST="DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_REQUEST_SUCCESS="DELETE_CLIENT_REQUEST_SUCCESS";
export const DELETE_CLIENT_REQUEST_FAILURE="DELETE_CLIENT_REQUEST_FAILURE";


export const EDIT_CLIENT_REQUEST="EDIT_CLIENT_REQUEST";
export const EDIT_CLIENT_REQUEST_SUCCESS="EDIT_CLIENT_REQUEST_SUCCESS";
export const EDIT_CLIENT_REQUEST_FAILURE="EDIT_CLIENT_REQUEST_FAILURE";



export const DISABLE_CLIENT_REQUEST="DISABLE_CLIENT_REQUEST";
export const DISABLE_CLIENT_REQUEST_SUCCESS="DISABLE_CLIENT_REQUEST_SUCCESS";
export const DISABLE_CLIENT_REQUEST_FAILURE="DISABLE_CLIENT_REQUEST_FAILURE";

