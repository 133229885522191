//reducers is a function

import {
  CREATE_USER_REQUEST,
  CREATE_USER_REQUEST_SUCCESS,
  CREATE_USER_REQUEST_FAILURE,
  VIEW_USER_REQUEST,
  VIEW_USER_REQUEST_SUCCESS,
  VIEW_USER_REQUEST_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_REQUEST_SUCCESS,
  DELETE_USER_REQUEST_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_REQUEST_SUCCESS,
  EDIT_USER_REQUEST_FAILURE,
  DATA_POINT_REQUEST,
  DATA_POINT_REQUEST_SUCCESS,
  DATA_POINT_REQUEST_FAILURE,
} from './actionTypes';

//VIEW

const ViewInitialState = {
  users: [],
  isLoading: false,
  error: undefined,
  isUsersList: false,
};

export const ViewUsersReducer = (state = ViewInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case VIEW_USER_REQUEST: {
      return { ...state, isUsersList: true };
    }
    case VIEW_USER_REQUEST_SUCCESS: {
      return { ...state, users: data, isUsersList: false };
    }
    case VIEW_USER_REQUEST_FAILURE: {
      const { error: msg } = action;
      return { ...state, error: msg, isUsersList: false };
    }
    default:
      return { ...state };
  }
};

const CreateInitialState = {
  users: [],
  isLoading: false,
  error: undefined,
};

export const CreateUsersReducer = (state = CreateInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case CREATE_USER_REQUEST:
      return { ...state, isLoading: true };
    case CREATE_USER_REQUEST_SUCCESS:
      return { ...state, users: data, isLoading: false };
    case CREATE_USER_REQUEST_FAILURE:
      const { error: msg } = action;
      return { ...state, error: msg, isLoading: false };
    default:
      return { ...state };
  }
};

const DeleteInitialState = {
  users: [],
  isLoading: false,
  error: undefined,
};

export const DeleteUsersReducer = (state = DeleteInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case DELETE_USER_REQUEST:
      return { ...state, isLoading: true };
    case DELETE_USER_REQUEST_SUCCESS:
      return { ...state, users: data, isLoading: false };
    case DELETE_USER_REQUEST_FAILURE:
      const { error: msg } = action;
      return { ...state, error: msg, isLoading: false };
    default:
      return { ...state };
  }
};

const EditInitialState = {
  users: [],
  isLoading: false,
  error: undefined,
};

export const EditUsersReducer = (state = EditInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDIT_USER_REQUEST:
      return { ...state, isLoading: true };
    case EDIT_USER_REQUEST_SUCCESS:
      return { ...state, isLoading: false };
    case EDIT_USER_REQUEST_FAILURE:
      const { error: msg } = payload;
      return { ...state, error: msg, isLoading: false };
    default:
      return { ...state };
  }
};

const PointsInitialState = {
  points: [],
  isLoading: false,
  error: undefined,
};

export const DataPointsReducer = (state = PointsInitialState, action) => {
  const { type, data } = action;

  switch (type) {
    case DATA_POINT_REQUEST: {
      return { ...state, isLoading: true };
    }
    case DATA_POINT_REQUEST_SUCCESS: {
      return { ...state, points: data, isLoading: false };
    }
    case DATA_POINT_REQUEST_FAILURE: {
      const { error: msg } = action;
      return { ...state, error: msg, isLoading: false };
    }
    default:
      return { ...state };
  }
};
