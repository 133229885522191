import { SET_SOC2_COMPLIANCE_DATA, TOGGLE_LOADING } from "./actionTypes";

const initialState = {
  isLoading: false,
  data: {},
};

export const Soc2ComplianceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case SET_SOC2_COMPLIANCE_DATA:
      const { data } = action;

      return {
        ...state,
        data: data,
      };

    default:
      return {
        ...state,
      };
  }
};
