import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles"; // Ensure this import is at the top
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentRole,
  toggleSwitchDashboard,
} from "../../reducers/SwitchRole/actions";

import "./index.scss";

// Define the Transition component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Define the role map
const roleMap = {
  ciso: "CISO",
  "claribel-super-admin": "Claribel Super Admin",
  operations: "Operations",
  admin: "Admin",
};

// Move useStyles outside of the component function
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    margin: 0,
    width: "80%", // Default for mobile devices

    [theme.breakpoints.up("sm")]: {
      width: "50%", // For small devices and up (600px and above)
    },
    [theme.breakpoints.up("md")]: {
      width: "50%", // For medium devices and up (960px and above)
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%", // For large devices and up (1280px and above)
    },
  },
}));

export default function SwitchRole() {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles(); // Now useStyles is accessible here
  const { isOpen: open, currentActiveRole } = useSelector(
    (state) => state.switchRole
  );
  const currentUser = useSelector((state) => state.currentUser.data);
  const activeUserPersona = localStorage.getItem("currentActive");
  const [value, setValue] = useState(
    currentActiveRole ? currentActiveRole : activeUserPersona
  );

  useEffect(() => {
    setValue(activeUserPersona);
  }, [activeUserPersona]);

  // Commented out unused effects
  // useEffect(() => {}, [open]);
  // useEffect(() => {}, [value]);
  // useEffect(() => {}, [currentActiveRole]);

  const toggleModal = () => {
    setValue(activeUserPersona);
    dispatch(toggleSwitchDashboard());
  };

  const handleChange = (event) => {
    const currentValue = event.target.value;
    console.log(event.target.value);
    setValue(currentValue);
  };

  function renderActiveUserRoles() {
    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) {
      return <h2>No Active User Available</h2>;
    }
    const sortedRoles = [...currentUser.roles].sort((a, b) => {
      let privilegeA = a.privilege.toLowerCase();
      let privilegeB = b.privilege.toLowerCase();
      return privilegeA.localeCompare(privilegeB);
    });
    return sortedRoles.map((currRole) => (
      <FormControlLabel
        style={{ justifyContent: "flex-start" }}
        key={currRole.privilege}
        value={currRole.privilege}
        control={<Radio />}
        label={currRole.privilege}
      />
    ));
  }

  function redirectToCorrectRoute() {
    toggleModal();
    setTimeout(() => {
      dispatch(changeCurrentRole(value));
    }, 100);

    if (value === "Data Manager") {
      history.push("/configuration-manager/periodic-data-feed/cyber-risk");
      return;
    }
    history.push("/" + value.toLowerCase().replaceAll(" ", "-"));
  }

  return (
    <Dialog
      className="dialog-paper"
      PaperProps={
        {
          // className: classes.dialogPaper,
        }
      }
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Switch Profile"}
      </DialogTitle>
      <DialogContent className="dialog-container">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="available roles"
            name="roles"
            value={value}
            onChange={handleChange}
          >
            {renderActiveUserRoles()}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button
          className="btn-cancel"
          onClick={toggleModal}
          style={{
            backgroundColor: "#333",
            color: "#fff",
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-done"
          onClick={redirectToCorrectRoute}
          style={{ backgroundColor: "#0090e7", color: "#fff" }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
