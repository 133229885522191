import { mergeNormalizedData, getNormalizedData } from "../../utils/service"

const SET_CONNECTION_LIST = 'SET_CONNECTION_LIST'
const SET_CONNECTION_TYPE_LIST = 'SET_CONNECTION_TYPE_LIST'
const ADD_OR_UPDATE_CONNECTION_LIST = 'ADD_OR_UPDATE_CONNECTION_LIST'
const DELETE_CONNECTION_DATA = 'DELETE_CONNECTION_DATA'

export const SetConnectionListAction = connectionList => ({
  type: SET_CONNECTION_LIST,
  payload: {
    connectionList,
  }
})

export const SetConnectionTypeListAction = connectionTypeList => ({
  type: SET_CONNECTION_TYPE_LIST,
  payload: {
    connectionTypeList,
  }
})

export const AddOrUpdateConnectionListAction = connectionList => ({
  type: ADD_OR_UPDATE_CONNECTION_LIST,
  payload: {
    connectionList,
  }
})

export const DeleteConnectionDataAction = connectionId => ({
  type: DELETE_CONNECTION_DATA,
  payload: {
    connectionId,
  }
})

const initialState = {
  connections: getNormalizedData([]),
  connectionTypes: getNormalizedData([]),
}

export default function connectionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONNECTION_LIST:
      return {
        ...state,
        connections: getNormalizedData(action.payload.connectionList),
      }
    case SET_CONNECTION_TYPE_LIST:
      return {
        ...state,
        connectionTypes: getNormalizedData(action.payload.connectionTypeList),
      }
    case ADD_OR_UPDATE_CONNECTION_LIST: {
      let normData = getNormalizedData(action.payload.connectionList)
      let newState = mergeNormalizedData(state.connections, normData)
      return {
        ...state,
        connections: newState
      }
    }
    case DELETE_CONNECTION_DATA: {
      let id = action.payload.connectionId
      let newState = JSON.parse(JSON.stringify(state))
      newState.connections.ids = newState.connections.ids.filter(x => x !== id)
      delete newState.connections.byId[id]
      return newState
    }
    default:
      return state
  }
}