export const GET_RISKS_REQUEST = 'GET_RISKS_REQUEST';
export const GET_RISKS_REQUEST_SUCCESS = 'GET_RISKS_REQUEST_SUCCESS';
export const GET_RISKS_REQUEST_FAILURE = 'GET_RISKS_REQUEST_FAILURE';

export const UPDATE_RISK_REQUEST = 'UPDATE_RISK_REQUEST';
export const UPDATE_RISK_REQUEST_SUCCESS = 'UPDATE_RISK_REQUEST_SUCCESS';
export const UPDATE_RISK_REQUEST_FAILURE = 'UPDATE_RISK_REQUEST_FAILURE';

export const GET_LIKELIHOODTYPES_REQUEST = 'GET_LIKELIHOODTYPES_REQUEST';
export const GET_LIKELIHOODTYPES_REQUEST_SUCCESS = 'GET_LIKELIHOODTYPES_REQUEST_SUCCESS';
export const GET_LIKELIHOODTYPES_REQUEST_FAILURE = 'GET_LIKELIHOODTYPES_REQUEST_FAILURE';

export const GET_IMPACTTYPES_REQUEST = 'GET_IMPACTTYPES_REQUEST';
export const GET_IMPACTTYPES_REQUEST_SUCCESS = 'GET_IMPACTTYPES_REQUEST_SUCCESS';
export const GET_IMPACTTYPES_REQUEST_FAILURE = 'GET_IMPACTTYPES_REQUEST_FAILURE';
