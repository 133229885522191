
const SET_EDIT_CONFIGURATION_DATA = 'SET_EDIT_CONFIGURATION_DATA'
const SET_EDIT_CONFIGURATION_FIELD = 'SET_EDIT_CONFIGURATION_FIELD'

export const SetEditConfigurationDataAction = configurationData => ({
  type: SET_EDIT_CONFIGURATION_DATA,
  payload: {
    configurationData,
  }
})
export const SetEditCofigurationFieldAction = (field, value) => ({
  type: SET_EDIT_CONFIGURATION_FIELD,
  payload: {
    field,
    value,
  }
})

const initialState = {
  editConfiguration: {}
}

export default function configurationPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_CONFIGURATION_DATA:
      return {
        ...state,
        editConfiguration: action.payload.configurationData,
      }
    case SET_EDIT_CONFIGURATION_FIELD: {
      let { editConfiguration } = state
      editConfiguration[action.payload.field] = action.payload.value
      return {
        ...state,
        editConfiguration,
      }
    }
    default:
      return state
  }
}