
const SET_NEW_ROLE_NAME = 'SET_NEW_ROLE_NAME'
const SET_NEW_ROLE_STATE = 'SET_NEW_ROLE_STATE'

export const SetNewRoleNameAction = name => ({
  type: SET_NEW_ROLE_NAME,
  payload: {
    name
  }
})

export const SetNewRoleStateAction = state => ({
  type: SET_NEW_ROLE_STATE,
  payload: {
    state
  }
})

const initialState = {
  newRoleName: "",
  newRoleEnabled: true,
}

export default function rolesPageReducer(state = initialState, action) {
  switch (action.type)
  {
    case SET_NEW_ROLE_NAME:
      return {
        ...state,
        newRoleName: action.payload.name
      }
    case SET_NEW_ROLE_STATE:
      return {
        ...state,
        newRoleEnabled: action.payload.state
      }
    default:
      return state
  }
}