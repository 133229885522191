export const GET_CISO_REQUEST = "GET_CISO_REQUEST";
export const GET_CISO_REQUEST_SUCCESS = "GET_CISO_REQEUST_SUCCESS";
export const GET_CISO_REQUEST_FAILURE = "GET_CISO_REQEUST_FAILURE";
export const GET_FFIEC_REQUEST = "GET_FFIEC_REQUEST";
export const GET_FFIEC_REQUEST_SUCCESS = "GET_FFIEC_REQUEST_SUCCESS";
export const GET_FFIEC_REQUEST_FAILURE = "GET_FFIEC_REQUEST_FAILURE";
export const GET_CIS_DASHBOARD_REQUEST = "GET_CIS_DASHBOARD_REQUEST";
export const GET_CIS_DASHBOARD_REQUEST_SUCCESS =
  "GET_CIS_DASHBOARD_REQEUST_SUCCESS";
export const GET_CIS_DASHBOARD_REQUEST_FAILURE =
  "GET_CIS_DASHBOARD_REQEUST_FAILURE";

export const GET_CIS_IMPACTED_AREA_REQUEST = "GET_CIS_IMPACTED_AREA_REQUEST";
export const GET_CIS_IMPACTED_AREA_REQUEST_SUCCESS =
  "GET_CIS_IMPACTED_AREA_REQEUST_SUCCESS";
export const GET_CIS_IMPACTED_AREA_REQUEST_FAILURE =
  "GET_CIS_IMPACTED_AREA_REQEUST_FAILURE";
// export const GET_ORGANIZATION_DATA = 'GET_ORGANIZATION_DATA';
export const GET_UPDATE_DATA_SECURITY_DOMAIN =
  "GET_UPDATE_DATA_SECURITY_DOMAIN";

export const SET_IMPACTED_NEW_AREA = "SET_IMPACTED_NEW_AREA";
export const SELECT_IMPACTED_NEW_AREA_ID = "SELECT_IMPACTED_NEW_AREA_ID";
export const SET_ACTIVE_TAB_DATA = "SET_ACTIVE_TAB_DATA";
export const SET_ACTIVE_TAB_LIST_DATA = "SET_ACTIVE_TAB_LIST_DATA";
export const REMOVE_FREOM_ACTIVE_TAB_LIST_DATA =
  "REMOVE_FREOM_ACTIVE_TAB_LIST_DATA";
export const GET_CMMC_DATA_REQUEST = "GET_CMMC_DATA_REQUEST";
export const GET_CMMC_DATA_SUCCESS = "GET_CMMC_DATA_SUCCESS";
export const GET_CMMC_DATA_FAILURE = "GET_CMMC_DATA_FAILURE";
