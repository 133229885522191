import { CHANGE_CURRENT_ROLE, TOGGLE_SWITCH_DASHBOARD } from './actionTypes';

export const toggleSwitchDashboard = () => ({
	type: TOGGLE_SWITCH_DASHBOARD,
});

export const changeCurrentRole = (role) => ({
	type: CHANGE_CURRENT_ROLE,
	role,
});
