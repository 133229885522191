export const GET_CALIBRATION_CATEGORY_REQUEST =
  'GET_CALIBRATION_CATEGORY_REQUEST';
export const GET_CALIBRATION_CATEGORY_REQUEST_SUCCESS =
  'GET_CALIBRATION_CATEGORY_REQUEST_SUCCESS';
export const GET_CALIBRATION_CATEGORY_REQUEST_FAILURE =
  'GET_CALIBRATION_CATEGORY_REQUEST_FAILURE';

export const GET_CALIBRATION_SUB_CATEGORY_REQUEST =
  'GET_CALIBRATION_SUB_CATEGORY_REQUEST';
export const GET_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS =
  'GET_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS';
export const GET_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE =
  'GET_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE';

export const GET_CALIBRATION_METRIC_REQUEST = 'GET_CALIBRATION_METRIC_REQUEST';
export const GET_CALIBRATION_METRIC_REQUEST_SUCCESS =
  'GET_CALIBRATION_METRIC_REQUEST_SUCCESS';
export const GET_CALIBRATION_METRIC_REQUEST_FAILURE =
  'GET_CALIBRATION_METRIC_REQUEST_FAILURE';

export const POST_CALIBRATION_CATEGORY_REQUEST =
  ' POST_CALIBRATION_CATEGORY_REQUEST';
export const POST_CALIBRATION_CATEGORY_REQUEST_SUCCESS =
  'POST_CALIBRATION_CATEGORY_REQUEST_SUCCESS';
export const POST_CALIBRATION_CATEGORY_REQUEST_FAILURE =
  'POST_CALIBRATION_CATEGORY_REQUEST_FAILURE';

export const POST_CALIBRATION_SUB_CATEGORY_REQUEST =
  'POST_CALIBRATION_SUB_CATEGORY_REQUEST';
export const POST_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS =
  'POST_CALIBRATION_SUB_CATEGORY_REQUEST_SUCCESS';
export const POST_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE =
  'POST_CALIBRATION_SUB_CATEGORY_REQUEST_FAILURE';

export const POST_CALIBRATION_METRIC_REQUEST =
  'POST_CALIBRATION_METRIC_REQUEST';
export const POST_CALIBRATION_METRIC_REQUEST_SUCCESS =
  'POST_CALIBRATION_METRIC_REQUEST_SUCCESS';
export const POST_CALIBRATION_METRIC_REQUEST_FAILURE =
  'POST_CALIBRATION_METRIC_REQUEST_FAILURE';
