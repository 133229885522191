import { useSelector } from "react-redux";
import { BASE_CONFIG } from "./baseUrl";

const encodeQueryData = (data) => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
};

const getApiUrl = (api, param, domain = "") => {
  const env = process.env.NODE_ENV.toLowerCase() || "development";
  let url = "";
  if (BASE_CONFIG && !domain) {
    domain = BASE_CONFIG[env];
  }

  const querystring = encodeQueryData(param);
  if (querystring) {
    url = `${domain}${api}?${querystring}`;
  } else {
    url = `${domain}${api}`;
  }
  return url;
};

export const getDefaultRole = (users) => {
  return new Promise((resolve, reject) => {
    // if (users.firstLogin === false) {
    const roles = users.roles;
    for (var i in roles) {
      const role = roles[i];
      if (role["name"] === "Executive" && role["isDefault"]) {
        resolve("ciso");
        break;
      }

      if (
        (role["name"] === "Operations" || role["name"] === "Data Manager") &&
        role["isDefault"]
      ) {
        // resolve("operations");
        resolve("configuration-manager");
        break;
      }

      if (role["name"] === "Admin" && role["isDefault"]) {
        resolve("admin");
        break;
      }

      if (role["name"] === "Claribel Super Admin" && role["isDefault"]) {
        resolve("claribel-super-admin");
        break;
      }
    }
    // }
    // else {
    // 	resolve('reset-password');
    // }
  });
};

export const isValidEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  //  var re = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?![\w-]+.com)([\w-]+\.)+[\w-]{2,4})?$/;
  return re.test(email);
};

export const convertInRange5 = (val) => {
  if (!val) {
    return null;
  }
  return ((val * 5) / 100).toFixed(2);
};

export const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

export const useIsLoggedIn = () => {
  const currentUser = useSelector((state) => state.currentUser.data);

  return currentUser?.username ? true : false;
};

export { encodeQueryData, getApiUrl };

export const isMobile = () => {
  return window.innerWidth < 520;
};
