export const CREATE_ROLE_REQUEST="CREATE_ROLE_REQUEST";
export const CREATE_ROLE_REQUEST_SUCCESS="CREATE_ROLE_REQUEST_SUCCESS";
export const CREATE_ROLE_REQUEST_FAILURE="CREATE_ROLE_REQUEST_FAILURE";



export const VIEW_ROLE_REQUEST="VIEW_ROLE_REQUEST";
export const VIEW_ROLE_REQUEST_SUCCESS="VIEW_ROLE_REQUEST_SUCCESS";
export const VIEW_ROLE_REQUEST_FAILURE="VIEW_ROLE_REQUEST_FAILURE";




export const DELETE_ROLE_REQUEST="DELETE_ROLE_REQUEST";
export const DELETE_ROLE_REQUEST_SUCCESS="DELETE_ROLE_REQUEST_SUCCESS";
export const DELETE_ROLE_REQUEST_FAILURE="DELETE_ROLE_REQUEST_FAILURE";


export const EDIT_ROLE_REQUEST="EDIT_ROLE_REQUEST";
export const EDIT_ROLE_REQUEST_SUCCESS="EDIT_ROLE_REQUEST_SUCCESS";
export const EDIT_ROLE_REQUEST_FAILURE="EDIT_ROLE_REQUEST_FAILURE";


export const DISABLE_ROLE_REQUEST="DISABLE_ROLE_REQUEST";
export const DISABLE_ROLE_REQUEST_SUCCESS="DISABLE_ROLE_REQUEST_SUCCESS";
export const DISABLE_ROLE_REQUEST_FAILURE="DISABLE_ROLE_REQUEST_FAILURE";
