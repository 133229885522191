export const GET_MODULE_STATUS_SELECTION_REQUEST = 'GET_MODULE_STATUS_SELECTION_REQUEST';
export const GET_MODULE_STATUS_SELECTION_REQUEST_SUCCESS = 'GET_MODULE_STATUS_SELECTION_REQEUST_SUCCESS';
export const GET_MODULE_STATUS_SELECTION_REQUEST_FAILURE = 'GET_MODULE_STATUS_SELECTION_REQEUST_FAILURE';



export const POST_MODULE_STATUS_SELECTION_REQUEST = 'POST_MODULE_STATUS_SELECTION_REQUEST';
export const POST_MODULE_STATUS_SELECTION_REQUEST_SUCCESS = 'POST_MODULE_STATUS_SELECTION_REQEUST_SUCCESS';
export const POST_MODULE_STATUS_SELECTION_REQUEST_FAILURE = 'POST_MODULE_STATUS_SELECTION_REQEUST_FAILURE';

