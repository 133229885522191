import React from "react";
import Loader from "../Loader";
import { transition } from "rsuite/esm/DOMHelper";
import { transform } from "lodash";

function LoadingContent() {
  const divStyle = {
    // position: "absolute",
    // left: "45%",
    // top: "43%",
    // height: "100%",
    // transition: transform("-50% -50%"),
  };
  return (
    <div style={divStyle} className="permission">
      <Loader />
    </div>
  );
}

export default LoadingContent;
