
const SET_CSV_FILE = 'SET_CSV_FILE'

export const SetCsvFileAction = file => ({
  type: SET_CSV_FILE,
  payload: {
    file,
  }
})

const initialState = {
  csvFile: undefined,
}

export default function csvDataPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CSV_FILE:
      return {
        ...state,
        csvFile: action.payload.file
      }
    default:
      return state
  }
}