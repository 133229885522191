const ADD_OR_UPDATE_DATAPOINT_LIST = 'ADD_OR_UPDATE_DATAPOINT_LIST';
const UPDATE_DATAPOINT_LIST = 'UPDATE_DATAPOINT_LIST';
const DELETE_DATAPOINT = 'DELETE_DATAPOINT';
const SET_DATAPOINT_LIST = 'SET_DATAPOINT_LIST';
const SET_DATAPOINT_CONNECTIONS_LIST = 'SET_DATAPOINT_CONNECTIONS_LIST';

export const SetDatapointListAction = (datapointList) => ({
  type: SET_DATAPOINT_LIST,
  payload: {
    datapointList,
  },
});

export const SetDatapointConnectionsListAction = (connectionList) => ({
  type: SET_DATAPOINT_CONNECTIONS_LIST,
  payload: {
    connectionList,
  },
});

export const AddOrUpdateDatapointListAction = (datapointObj) => {
  return {
    type: ADD_OR_UPDATE_DATAPOINT_LIST,
    payload: {
      datapointObj,
    },
  };
};

export const UpdateDatapointListAction = (datapointObj) => {
  return {
    type: UPDATE_DATAPOINT_LIST,
    payload: {
      datapointObj,
    },
  };
};

export const DeleteDatapointDataAction = (id) => ({
  type: DELETE_DATAPOINT,
  payload: {
    id,
  },
});
const initialState = {
  datapointList: [],
  datapointConnectionsList: [],
};

export default function datapointReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATAPOINT_LIST: {
      return { ...state, datapointList: action.payload.datapointList };
    }

    case SET_DATAPOINT_CONNECTIONS_LIST:
      return {
        ...state,
        datapointConnectionsList: action.payload.connectionList,
      };

    case ADD_OR_UPDATE_DATAPOINT_LIST: {
      return {
        ...state,
        datapointList: [...state.datapointList, action.payload.datapointObj],
      };
    }

    case UPDATE_DATAPOINT_LIST: {
      return {
        ...state,
        datapointList: [
          ...state.datapointList.filter(
            (x) => x.id !== action.payload.datapointObj.id
          ),
          action.payload.datapointObj,
        ],
      };
    }

    case DELETE_DATAPOINT: {
      let id = action.payload.id;
      let listAfterDeletion = state.datapointList.filter((dp) => dp.id !== id);
      return {
        ...state,
        datapointList: listAfterDeletion,
      };
    }

    default:
      return state;
  }
}
