import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PermissionErorComponent from "../../Shared/Permission";
import LoginErrorComponent from "../../Shared/LoginError";
import LoadingContentComponent from "../../Shared/LoadingContent";

import { useSelector, useDispatch } from "react-redux";
import { StorageService } from "../../services/storageService.js";
import { getCurrentUSerDetails } from "../../reducers/Auth/actions";

import {
  RESET_PASSWORD,
  PROFILE_SETTINGS,
} from "../../Shared/AppConstants/index";

const PrivateRoute = ({ role, ...props }) => {
  const currentUser = useSelector((state) => state.currentUser.data);
  const myDetails = useSelector((state) => state.myDetails.data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (StorageService.isPresent("user")) {
      const user = JSON.parse(StorageService.get("user"));
      if (!myDetails) {
        dispatch(getCurrentUSerDetails());
      }
    }
  }, [myDetails]);

  if (role === "login") {
    if (StorageService.isPresent("user")) {
      if (currentUser.id === undefined) return <LoadingContentComponent />;

      return <Redirect to={`/${currentUser.defaultRole}`} />;
    } else {
      return <Route {...props} />;
    }
  } else {
    //if the user is logged in

    if (StorageService.isPresent("user")) {
      //redirection to the id allowed page

      if (currentUser.id === undefined) return <LoadingContentComponent />;

      const rolesAllowed = currentUser.roles.map((role, index) => {
        return role.name.toLowerCase();
      });

      console.log(rolesAllowed);
      console.log(role);

      if (role == "operations" && rolesAllowed.includes("data manager")) {
        return <Route {...props} />;
      }

      if (rolesAllowed.includes(role)) return <Route {...props} />;

      if (role.toLowerCase() === RESET_PASSWORD) {
        return <Route {...props} />;
      } else if (role.toLowerCase() === PROFILE_SETTINGS) {
        return <Route {...props} />;
      }

      return <PermissionErorComponent />;
    }
    //else
    else {
      //move to login
      return <LoginErrorComponent />;
    }
  }
};

export default PrivateRoute;
