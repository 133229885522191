
const START_USER_EDIT = 'START_USER_EDIT'
const STOP_USER_EDIT = 'STOP_USER_EDIT'
const SET_EDIT_USER_FIELD = 'SET_EDIT_USER_FIELD'
const SET_CREATE_USER_FIELD = 'SET_CREATE_USER_FIELD'
const RESET_CREATE_USER_INFO = 'RESET_CREATE_USER_INFO'

export const StartUserEditAction = userInfo => ({
  type: START_USER_EDIT,
  payload: {
    userInfo,
  }
})

export const SetEditUserFieldAction = (field, value) => ({
  type: SET_EDIT_USER_FIELD,
  payload: {
    field,
    value,
  }
})

export const StopUserEditAction = () => ({
  type: STOP_USER_EDIT,
  payload: {}
})

export const SetCreateUserFieldAction = (field, value) => ({
  type: SET_CREATE_USER_FIELD,
  payload: {
    field,
    value
  }
})

export const ResetCreateUserInfo = () => ({
  type: RESET_CREATE_USER_INFO,
  payload: {}
})

const initialState = {
  isEditing: false,
  editUserInfo: {},
  createUserInfo: {},
}

export default function usersPageReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CREATE_USER_INFO:
      return {
        ...state,
        createUserInfo: {}
      }
    case SET_EDIT_USER_FIELD: {
      let { editUserInfo } = state
      editUserInfo[action.payload.field] = action.payload.value 
      return {
        ...state,
        editUserInfo,
      }
    }
    case SET_CREATE_USER_FIELD: {
      let { createUserInfo } = state
      createUserInfo[action.payload.field] = action.payload.value
      return {
        ...state,
        createUserInfo,
      }
    }
    case STOP_USER_EDIT:
      return {
        ...state,
        isEditing: false,
      }
    case START_USER_EDIT:
      return {
        ...state,
        isEditing: true,
        editUserInfo: action.payload.userInfo
      }
    default:
      return state
  }
}