import { getNormalizedData, mergeNormalizedData } from '../../utils/service';

const SET_UESR_LIST = 'SET_USER_LIST';
const ADD_OR_UPDATE_USER_LIST = 'ADD_OR_UPDATE_USER_LIST';
const DELETE_USER_DATA = 'DELETE_USER_DATA';

export const SetUserListAction = (userList) => ({
  type: SET_UESR_LIST,
  payload: {
    userList,
  },
});

export const AddOrUpdateUserListAction = (userList) => ({
  type: ADD_OR_UPDATE_USER_LIST,
  payload: {
    userList,
  },
});

export const DeleteUserDataAction = (id) => ({
  type: DELETE_USER_DATA,
  payload: {
    id,
  },
});

const initialState = getNormalizedData([]);

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UESR_LIST:
      state = getNormalizedData(action.payload.userList);
      return state;
    case ADD_OR_UPDATE_USER_LIST: {
      let normData = getNormalizedData(action.payload.userList);
      let newState = mergeNormalizedData(state, normData);
      return newState;
    }
    case DELETE_USER_DATA: {
      let id = action.payload.id;
      let newState = JSON.parse(JSON.stringify(state));
      newState.ids = newState.ids.filter((x) => x !== id);
      delete newState.byId[id];
      return newState;
    }
    default:
      return state;
  }
}
