export const CREATE_USER_REQUEST="CREATE_USER_REQUEST";
export const CREATE_USER_REQUEST_SUCCESS="CREATE_USER_REQUEST_SUCCESS";
export const CREATE_USER_REQUEST_FAILURE="CREATE_USER_REQUEST_FAILURE";



export const VIEW_USER_REQUEST="VIEW_USER_REQUEST";
export const VIEW_USER_REQUEST_SUCCESS="VIEW_USER_REQUEST_SUCCESS";
export const VIEW_USER_REQUEST_FAILURE="VIEW_USER_REQUEST_FAILURE";




export const DELETE_USER_REQUEST="DELETE_USER_REQUEST";
export const DELETE_USER_REQUEST_SUCCESS="DELETE_USER_REQUEST_SUCCESS";
export const DELETE_USER_REQUEST_FAILURE="DELETE_USER_REQUEST_FAILURE";


export const EDIT_USER_REQUEST="EDIT_USER_REQUEST";
export const EDIT_USER_REQUEST_SUCCESS="EDIT_USER_REQUEST_SUCCESS";
export const EDIT_USER_REQUEST_FAILURE="EDIT_USER_REQUEST_FAILURE";




export const DISABLE_USER_REQUEST="DISABLE_USER_REQUEST";
export const DISABLE_USER_REQUEST_SUCCESS="DISABLE_USER_REQUEST_SUCCESS";
export const DISABLE_USER_REQUEST_FAILURE="DISABLE_USER_REQUEST_FAILURE";

export const DATA_POINT_REQUEST="DATA_POINT_REQUEST";
export const DATA_POINT_REQUEST_SUCCESS="DATA_POINT_REQUEST_SUCCESS";
export const DATA_POINT_REQUEST_FAILURE="DATA_POINT_REQUEST_FAILURE";




