export const GET_CYBER_DATA_POINTS_REQUEST = 'GET_CYBER_DATA_POINTS_REQUEST';
export const GET_CYBER_DATA_POINTS_REQUEST_SUCCESS =
  'GET_CYBER_DATA_POINTS_REQEUST_SUCCESS';
export const GET_CYBER_DATA_POINTS_REQUEST_FAILURE =
  'GET_CYBER_DATA_POINTS_REQEUST_FAILURE';

export const GET_CYBER_TABLE_DATA_REQUEST = 'GET_CYBER_TABLE_DATA_REQUEST';
export const GET_CYBER_TABLE_DATA_REQUEST_SUCCESS =
  'GET_CYBER_TABLE_DATA_REQEUST_SUCCESS';
export const GET_CYBER_TABLE_DATA_REQUEST_FAILURE =
  'GET_CYBER_TABLE_DATA_REQEUST_FAILURE';

export const UPDATE_DATA_POINTS_REQUEST = 'UPDATE_DATA_POINTS_REQUEST';
export const UPDATE_DATA_POINTS_REQUEST_SUCCESS =
  'UPDATE_DATA_POINTS_REQUEST_SUCCESS';
export const UPDATE_DATA_POINTS_REQUEST_FAILURE =
  'UPDATE_DATA_POINTS_REQUEST_FAILURE';
export const UPDATE_DATAPOINTSDENOMINATOR_DATA =
  'UPDATE_DATAPOINTSDENOMINATOR_DATA';
export const UPDATE_DATAPOINTSNUMERATOR_DATA =
  'UPDATE_DATAPOINTSNUMERATOR_DATA';
export const UPDATE_DATAPOINTS_DATA = 'UPDATE_DATAPOINTS_DATA';
