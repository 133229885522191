import {
  GET_FEATURE_MODULE_STATUS_SELECTION_REQUEST,
  GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_SUCCESS,
  GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_FAILURE,
} from './actiontype';

const selectionsinitialState = {
  data: {},
  isLoading: false,
  error: undefined,
};

export const FeatureModuleStatusSelectionsRedcuer = (
  state = selectionsinitialState,
  action,
) => {
  switch (action.type) {
    case GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case GET_FEATURE_MODULE_STATUS_SELECTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
