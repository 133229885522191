import React from 'react';
import { Redirect } from 'react-router-dom';
function index(props) {
  return (
    <div
      style={{ textAlign: 'center', marginTop: '50px' }}
      className="permission"
    >
      <Redirect to="/login" />
    </div>
  );
}

export default index;
