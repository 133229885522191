import {
  GET_CONNECTIONS_REQUEST,
  GET_CONNECTIONS_REQUEST_SUCCESS,
  GET_CONNECTIONS_REQUEST_FAILURE,
  TEST_CONNECTION_REQUEST,
  TEST_CONNECTION_REQUEST_SUCCESS,
  TEST_CONNECTION_REQUEST_FAILURE,
  DELETE_CONNECTION_REQUEST,
  DELETE_CONNECTION_REQUEST_SUCCESS,
  DELETE_CONNECTION_REQUEST_FAILURE,
  CREATE_CONNECTION_REQUEST,
  CREATE_CONNECTION_REQUEST_SUCCESS,
  CREATE_CONNECTION_REQUEST_FAILURE,
} from './actionTypes';

const initialState = {
  connections: [],
  isLoading: false,
  error: undefined,
  isConnectionData: false,
};

export const connectionsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONNECTIONS_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        connections: data,
        isConnectionData: false,
      };
    }

    case GET_CONNECTIONS_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isConnectionData: false,
      };
    }

    case GET_CONNECTIONS_REQUEST: {
      return {
        ...state,
        isConnectionData: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const testInitialState = {
  status: '',
  isLoading: false,
  error: undefined,
};

export const testConnectionReducer = (state = testInitialState, action) => {
  switch (action.type) {
    case TEST_CONNECTION_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        status: data,
        isLoading: false,
      };
    }

    case TEST_CONNECTION_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case TEST_CONNECTION_REQUEST: {
      return {
        ...state,
        status: '',
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const createConnectionInitialState = {
  data: '',
  isLoading: false,
  error: undefined,
};

export const createConnectionReducer = (
  state = createConnectionInitialState,
  action,
) => {
  switch (action.type) {
    case CREATE_CONNECTION_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case CREATE_CONNECTION_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case CREATE_CONNECTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const deleteConnectionInitialState = {
  data: '',
  isLoading: false,
  error: undefined,
};

export const deleteConnectionReducer = (
  state = deleteConnectionInitialState,
  action,
) => {
  switch (action.type) {
    case DELETE_CONNECTION_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }

    case DELETE_CONNECTION_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case DELETE_CONNECTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
