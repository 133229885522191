import "./index.scss";
import React, { useState, useEffect } from "react";
import { Link, withRouter, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AVATAR } from "../../assets";
import { BRAND_LOGO, USER_IMAGE, USER_DEFAULT } from "../../assets";
import { getCurrentUSerDetails } from "../../reducers/Auth/actions";

const ProfileSettings = (props) => {
  const {
    match: { url },
  } = props;

  const match = useRouteMatch();
  const dispatch = useDispatch();

  let requiredPath = props.location.pathname.split("/")[2];

  if (
    !(
      requiredPath === "user-management" ||
      requiredPath === "module-assignment" ||
      requiredPath === "role-management" ||
      requiredPath === "module-assignment"
    )
  )
    requiredPath = "module-assignment";

  const [activePath, changeActivePath] = useState(requiredPath);

  const isPathActive = (linkPath) => {
    return activePath === linkPath;
  };

  const myDetails = useSelector((state) => state.myDetails.data);

  useEffect(() => {
    // if (!myDetails) dispatch(getCurrentUSerDetails());
  }, []);

  let firstName = myDetails ? myDetails.firstName : "";
  let lastName = myDetails ? myDetails.lastName : "";
  let email = myDetails ? myDetails.email : "";

  if (!firstName) firstName = "Guest";
  if (!lastName) lastName = "User";
  return (
    <div className="profile-settings-dashboard">
      <div className="main-content">
        {/* <h3 className="dashboard-heading">Service Now Dashboard</h3> */}
        <div className="container">
          <div className="row gutters">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="card h-100">
                <div className="card-body">
                  <div className="account-settings">
                    <div className="user-profile">
                      <div className="user-avatar">
                        <img src={USER_DEFAULT} alt="User Profile Picture" />
                      </div>
                      <h5 className="user-name">
                        {firstName + " " + lastName}
                      </h5>
                      <h6 className="user-email">{email}</h6>
                    </div>
                    <div className="about">
                      {/* <h5>About</h5>
                                            <p>We are here for you. Any of your problem we have the best solution.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <div className="card h-100">
                <div className="card-body">
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 className="mb-2 text-primary">Personal Details</h6>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="fullName">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          placeholder={firstName + " " + lastName}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="eMail">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="eMail"
                          placeholder={email}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          placeholder="Enter phone number"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="website">Website URL</label>
                        <input
                          type="url"
                          className="form-control"
                          id="website"
                          placeholder="Website url"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="website">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="website"
                          placeholder="Company Name"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="about">About</label>
                        <input
                          type="text"
                          className="form-control"
                          id="about"
                          placeholder="About"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h6 className="mt-3 mb-2 text-primary">Address</h6>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="Street">Street</label>
                        <input
                          type="name"
                          className="form-control"
                          id="Street"
                          placeholder="Enter Street"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="ciTy">City</label>
                        <input
                          type="name"
                          className="form-control"
                          id="ciTy"
                          placeholder="Enter City"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="sTate">State</label>
                        <input
                          type="text"
                          className="form-control"
                          id="sTate"
                          placeholder="Enter State"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label htmlFor="zIp">Zip Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="zIp"
                          placeholder="Zip Code"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="text-right mt20">
                        <Link to={`/user-management`}>
                          <button
                            type="button"
                            id="submit"
                            name="submit"
                            className="btn btn-secondary mr20"
                          >
                            Cancel
                          </button>
                        </Link>
                        <button
                          type="button"
                          id="submit"
                          name="submit"
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileSettings;
