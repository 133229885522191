import React from "react";
import errorLottie from "./../../../assets/lotties/error.json";
import Lottie from "lottie-react";
import "./index.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ErrorPage = ({ error }) => {
  return (
    <div className="error-page">
      <Lottie
        style={{
          height: 200,
          width: 200,
        }}
        loop={false}
        animationData={errorLottie}
      />
      <div className="error-msg">
        <h4>Something went wrong!</h4>

        <Link to="/" className="btn">
          Refresh Page
        </Link>
      </div>
    </div>
  );
};
export default ErrorPage;
