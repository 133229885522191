import React from 'react'
import './index.scss'

const Loader = () => (
  <div className="spinner-wrapper">
    <div className="loader"></div>
  </div>
)

export default Loader
