export const ROLES = {
  ADMIN: 1,
  OPERATIONS: 2,
  CISO: 3,
};

export const selectOptions = [
  { value: 'Last 12 Months', label: 'Last 12 Months' },
  { value: 'Current Month', label: 'Current Month' },
  { value: 'Year-To-Date', label: 'Year-To-Date' },
];

export const ORG_NAME = "organization_name"
