import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeCurrentCompany,
    toggleSwitchCompany,
} from '../../reducers/SwitchCompany/actions';

import './index.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const companyMap = {
    'ciso': 'A',
    'claribel-super-admin': 'B',
    'operations': 'C',
    'admin': "D"
}

export default function SwitchCompany() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isOpen: open, currentActiveRole } = useSelector(
        (state) => state.switchCompany
    );
    const currentUser = useSelector((state) => state.currentUser.data);

    const [value, setValue] = useState(
        currentActiveRole ? currentActiveRole : capitalize(currentUser.defaultRole)
    );

    useEffect(() => {
        if (!currentActiveRole) {
            const {
                location: { pathname },
            } = history;

            const activePath = pathname?.split('/')[1];

            setTimeout(() => {
                dispatch(changeCurrentCompany(companyMap[activePath]));
            }, 100);
            setValue(companyMap[activePath]);
        }
    }, []);

    useEffect(() => { }, [open]);
    useEffect(() => { }, [value]);

    useEffect(() => {
    }, [currentActiveRole]);

    const toggleModal = () => {
        dispatch(toggleSwitchCompany());
    };

    const handleChange = (event) => {
        const currentValue = event.target.value;
        setValue(currentValue);
    };

    function capitalize(string) {
        if (!string || typeof string !== 'string') return undefined;
        if (string === string.toUpperCase()) return string;
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function renderActiveUserCompanies() {
        if (!currentUser) {
            return <h2>No Active Company Available</h2>;
        }

        return currentUser?.roles?.map((currRole) => (
            <FormControlLabel
                key={currRole.name}
                value={currRole.name}
                control={<Radio />}
                label={currRole.name}
            />
        ));
    }

    function redirectToCorrectRoute() {
        toggleModal();
        setTimeout(() => {
            dispatch(changeCurrentCompany(value));
        }, 100);
        history.push('/' + value.toLowerCase().replaceAll(' ', '-'));
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={toggleModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{'Switch Company'}</DialogTitle>
            <DialogContent className="switch-role-modal">
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="available roles"
                        name="roles"
                        value={value}
                        onChange={handleChange}
                    >
                        {renderActiveUserCompanies()}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModal} color="primary">
                    Cancel
				</Button>
                <Button onClick={redirectToCorrectRoute} color="primary">
                    Done
				</Button>
            </DialogActions>
        </Dialog>
    );
}
