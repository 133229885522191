import {
  DOWNLOAD_CYBER_CSV_REQUEST,
  DOWNLOAD_CYBER_CSV_REQUEST_SUCCESS,
  DOWNLOAD_CYBER_CSV_REQUEST_FAILURE,
  UPLOAD_CYBER_CSV_REQUEST,
  UPLOAD_CYBER_CSV_REQUEST_SUCCESS,
  UPLOAD_CYBER_CSV_REQUEST_FAILURE,
} from './actionTypes';

const DownloadCyberinitialState = {
  data: null,
  isLoading: false,
  error: undefined,
  isDownload: false,
};

export const DownloadCyberReducer = (
  state = DownloadCyberinitialState,
  action,
) => {
  switch (action.type) {
    case DOWNLOAD_CYBER_CSV_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        connections: data,
        isDownload: false,
      };
    }

    case DOWNLOAD_CYBER_CSV_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isDownload: false,
      };
    }

    case DOWNLOAD_CYBER_CSV_REQUEST: {
      return {
        ...state,
        isDownload: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const UploadCyberinitialState = {
  data: null,
  isLoading: false,
  error: undefined,
  isUpload: false,
};

export const UploadCyberReducer = (state = UploadCyberinitialState, action) => {
  switch (action.type) {
    case UPLOAD_CYBER_CSV_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        connections: data,
        isUpload: false,
      };
    }

    case UPLOAD_CYBER_CSV_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isUpload: false,
      };
    }

    case UPLOAD_CYBER_CSV_REQUEST: {
      return {
        ...state,
        isUpload: true,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
