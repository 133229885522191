export const GET_PCI_COMPLIANCE_REQUEST = "GET_PCI_COMPLIANCE_REQUEST";
export const GET_PCI_COMPLIANCE_REQUEST_SUCCESS =
  "GET_PCI_COMPLIANCE_REQEUST_SUCCESS";
export const GET_PCI_COMPLIANCE_REQUEST_FAILURE =
  "GET_PCI_COMPLIANCE_REQEUST_FAILURE";

export const SET_ACTIVE_TAB_LIST_DATA_COMPLIANCE =
  "SET_ACTIVE_TAB_LIST_DATA_COMPLIANCE";
export const REMOVE_FREOM_ACTIVE_TAB_LIST_DATA_COMPLIANCE =
  "REMOVE_FREOM_ACTIVE_TAB_LIST_DATA_COMPLIANCE";
