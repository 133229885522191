import { API_ROOT, API_CALL_STATE } from './api';
import { toast } from 'react-toastify';
// import './style.css';

export const history = require('history').createHashHistory();

export const generateApiUrl = (url, urlParams) => {
  if (!Array.isArray(urlParams)) {
    urlParams = [urlParams];
  }

  for (let i = 0; i < urlParams.length; ++i) {
    url = url.replace(`{${i}}`, urlParams[i]);
  }

  return API_ROOT + url;
};

export const getNormalizedData = (list) => {
  let result = {
    ids: [],
    byId: {},
  };

  if (true !== Array.isArray(list)) return result;

  list.forEach((item) => {
    if (undefined !== item.id) {
      result.ids.push(item.id);
      result.byId[item.id] = item;
    }
  });

  return result;
};

export const checkUIBlock = (apiState) => {
  if (undefined === apiState) return false;

  if (API_CALL_STATE.PROCESS === apiState.state) return true;

  return false;
};

export const mergeNormalizedData = (data1, data2) => {
  let result = JSON.parse(JSON.stringify(data1));
  data2.ids.forEach((id) => {
    if (result.ids.indexOf(id) < 0) result.ids.push(id);

    result.byId[id] = data2.byId[id];
  });
  return result;
};

export const isEmpty = (str) => {
  return undefined === str || '' === str;
};

export const isEmptyString = (str) => {
  return undefined === str || '' === str.toString();
};

export const hasEmptyValue = (arr) => {
  if (!Array.isArray(arr)) return false;

  let result = false;

  arr.forEach((x) => {
    if (isEmptyString(x)) {
      result = true;
    }
  });
  return result;
};

function showToast(type, msg, baseConfig = {}) {
  // return;
  type(msg, {
    autoClose: 5000,
    position: 'top-center',
    draggable: true,
    ...baseConfig,
  });
}

export const formatDate = (date) => {
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `${month}-${year}`;
};

export const showError = (msg, baseConfig = {}) =>
  showToast(toast.error, msg, baseConfig);
export const showSuccess = (msg, baseConfig = {}) =>
  showToast(toast.success, msg, baseConfig);
export const showWarning = (msg, baseConfig = {}) =>
  showToast(toast.warning, msg, baseConfig);
export const showInformation = (msg, baseConfig = {}) =>
  showToast(toast.info, msg, baseConfig);
export const loaderToast = (msg, baseConfig = {}) =>
  toast.info(msg, {
    autoClose: false,
    position: 'top-center',
    draggable: true,
    ...baseConfig,
  });
