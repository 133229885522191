export const GET_FEATURE_MODULE_STATUS_SELECTION_REQUEST =
  'GET_FEATURE_MODULE_STATUS_SELECTION_REQUEST';
export const GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_SUCCESS =
  'GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_SUCCESS';
export const GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_FAILURE =
  'GET_FEATURE_MODULE_STATUS_SELECTION_REQEUST_FAILURE';

export const POST_FEATURE_MODULE_STATUS_SELECTION_REQUEST =
  'POST_FEATURE_MODULE_STATUS_SELECTION_REQUEST';
export const POST_FEATURE_MODULE_STATUS_SELECTION_REQUEST_SUCCESS =
  'POST_FEATURE_MODULE_STATUS_SELECTION_REQEUST_SUCCESS';
export const POST_FEATURE_MODULE_STATUS_SELECTION_REQUEST_FAILURE =
  'POST_FEATURE_MODULE_STATUS_SELECTION_REQEUST_FAILURE';
