import { CHANGE_CURRENT_ROLE, TOGGLE_SWITCH_DASHBOARD } from "./actionTypes";

const initialState = {
  isOpen: false,
  currentActiveRole: undefined,
};

export const switchRole = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SWITCH_DASHBOARD: {
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    }
    case CHANGE_CURRENT_ROLE: {
      const { role } = action;
      localStorage.setItem("currentActive", role);
      return {
        ...state,
        currentActiveRole: role,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
