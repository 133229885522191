import {
  GET_BUDGET_REQUEST,
  GET_BUDGET_REQUEST_FAILURE,
  GET_BUDGET_REQUEST_SUCCESS,
  GET_BUDGET_SUMMARY_REQUEST,
  GET_BUDGET_SUMMARY_REQUEST_FAILURE,
  GET_BUDGET_SUMMARY_REQUEST_SUCCESS,
  GET_BUDGET_PROGRAM_SUMMARY_REQUEST,
  GET_BUDGET_PROGRAM_SUMMARY_REQUEST_FAILURE,
  GET_BUDGET_PROGRAM_SUMMARY_REQUEST_SUCCESS,
} from "./actionTypes";

const initialState = {
  budgetData: undefined,
  budgetSummary: undefined,
  budgetProgramSummary: undefined,
  isLoading: false,
  error: undefined,
};

export const budgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUDGET_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        budgetData: data,
        isLoading: false,
      };
    }
    case GET_BUDGET_SUMMARY_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        budgetSummary: data,
        isLoading: false,
      };
    }
    case GET_BUDGET_PROGRAM_SUMMARY_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        budgetProgramSummary: data,
        isLoading: false,
      };
    }
    case GET_BUDGET_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_BUDGET_SUMMARY_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_BUDGET_PROGRAM_SUMMARY_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_BUDGET_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_BUDGET_PROGRAM_SUMMARY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_BUDGET_SUMMARY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
