import {
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_REQUEST_SUCCESS,
  VALIDATE_OTP_REQUEST_FAILURE,
  SIGININ_REQUEST,
  SIGININ_REQUEST_SUCCESS,
  SIGININ_REQUEST_FAILURE,
  SEND_OTP_REQUEST,
  SEND_OTP_REQUEST_SUCCESS,
  SEND_OTP_REQUEST_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_REQUEST_SUCCESS,
  SIGNUP_REQUEST_FAILURE,
  GENERATE_TOKEN_REQUEST,
  GENERATE_TOKEN_REQUEST_SUCCESS,
  GENERATE_TOKEN_REQUEST_FAILURE,
  GETUSER_REQUEST,
  GETUSER_REQUEST_SUCCESS,
  GETUSER_REQUEST_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
} from './actionTypes';

const SendOtpinitialState = {
  data: {},
  isLoading: false,
  error: undefined,
};

export const SendOtpReducer = (state = SendOtpinitialState, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SEND_OTP_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case SEND_OTP_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        data: {},
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
const SignIninitialState = {
  data: {},
  isLoading: false,
  error: undefined,
};
export const SignInReducer = (state = SignIninitialState, action) => {
  switch (action.type) {
    case SIGININ_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SIGININ_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case SIGININ_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        data: {},
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const SignUpinitialState = {
  data: {},
  isLoading: false,
  error: undefined,
};

export const SignUpReducer = (state = SignUpinitialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SIGNUP_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case SIGNUP_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        data: {},
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const ValidateOTPinitialState = {
  data: null,
  isLoading: false,
  error: undefined,
};

export const validateOTPReducer = (state = ValidateOTPinitialState, action) => {
  switch (action.type) {
    case VALIDATE_OTP_REQUEST: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case VALIDATE_OTP_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case VALIDATE_OTP_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const myDetailsInitialState = {
  data: null,
  isLoading: false,
  error: undefined,
};

export const myDetailsReducer = (state = myDetailsInitialState, action) => {
  switch (action.type) {
    case GETUSER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GETUSER_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case GETUSER_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        data: {},
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const resetPasswordInitialState = {
  data: {},
  isResetPasswordClicked: false,
  error: undefined,
};

export const resetPasswordReducer = (
  state = resetPasswordInitialState,
  action,
) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST: {
      const { data } = action;
      return {
        ...state,
        data: data,
        isResetPasswordClicked: true,
      };
    }
    case RESET_PASSWORD_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isResetPasswordClicked: false,
      };
    }
    case RESET_PASSWORD_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        data: {},
        error,
        isResetPasswordClicked: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const GenerateTokeninitialState = {
  data: null,
  isLoading: false,
  error: undefined,
};

export const generateTOKENReducer = (
  state = GenerateTokeninitialState,
  action,
) => {
  switch (action.type) {
    case GENERATE_TOKEN_REQUEST: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case GENERATE_TOKEN_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data: data,
        isLoading: false,
      };
    }
    case GENERATE_TOKEN_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
