import React, {
  createContext,
  lazy,
  Suspense,
  useEffect,
  useState,
} from "react";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./HOC/PrivateRoute";
import { Switch, Redirect, Route } from "react-router-dom";
import SwitchRole from "./Shared/SwitchRole";
import SwitchCompany from "./Shared/SwitchCompany";
import Loader from "./Shared/Loader";
import {
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  PROFILE_SETTINGS,
  SINGUP_CONTAINER,
  SINGUP,
  SIGNUP,
} from "./Shared/AppConstants/index";
import ProfileSettings from "./Components/ProfileComponent";
import Help from "./Components/General/Help.js/Help";
import { useIsLoggedIn } from "./utils/utility";

import { useDispatch, useSelector } from "react-redux";
import { getCurrentUSerDetails } from "./reducers/Auth/actions";
const HomeContainer = lazy(() => import("./Containers/HomeContainer"));
const OperationsContainer = lazy(() =>
  import("./Containers/OperationsContainer")
);

const AdminContainer = lazy(() => import("./Containers/AdminContainer"));
const ClaribelSuperAdminContainer = lazy(() =>
  import("./Containers/ClaribelSuperAdmin")
);
const LoginContainer = lazy(() => import("./Containers/LoginContainer"));
const ResetPasswordContainer = lazy(() =>
  import("./Containers/ResetPasswordContainer")
);
const ForgotPasswordContainer = lazy(() =>
  import("./Containers/ForgotPasswordContainer")
);
// const SignUpContainer = lazy(() => import('./Containers/SignUpContainer'));
const Signup = lazy(() => import("./Containers/Signup"));
const ReportAdvance = lazy(() =>
  import(
    "./Components/ReportsDashboard/ReportsComponent/StateReportAdvance/StateReportAdvance"
  )
);
// export const ThemeContext = createContext(null);
export default function App(props) {
  // const dispatch = useDispatch();
  // const myDetails = useSelector((state) => state.myDetails.data);

  // useEffect(() => {
  //   if (!myDetails) dispatch(getCurrentUSerDetails());
  // }, [myDetails]);
  /*theme */
  // const [theme, setTheme] = useState("dark");
  // const toggleTheme = () => {
  //   setTheme((curr) => (curr === "light" ? "dark" : "light"));
  // };
  /*end*/
  return (
    // <ThemeContext.Provider value={{ theme, toggleTheme }}>
    //   <div id={theme}>
    <Suspense fallback={""}>
      <div>
        <ToastContainer limit={1} />
        <Switch>
          <PrivateRoute role="login" path="/login" component={LoginContainer} />
          <PrivateRoute
            role={RESET_PASSWORD}
            path={`/${RESET_PASSWORD}`}
            component={ResetPasswordContainer}
          />
          <PrivateRoute
            role={PROFILE_SETTINGS}
            path={`/${PROFILE_SETTINGS}`}
            component={ProfileSettings}
          />
          <PrivateRoute
            role="executive"
            path="/ciso"
            component={HomeContainer}
          />
          <Route
            role={FORGOT_PASSWORD}
            path={`/${FORGOT_PASSWORD}`}
            component={ForgotPasswordContainer}
          />
          {/* <Route role={SINGUP_CONTAINER} path={`/${SINGUP_CONTAINER}`} component={SignUpContainer} /> */}
          <Route role={SIGNUP} path={`/${SIGNUP}`} component={Signup} />
          <PrivateRoute
            role="operations"
            path="/configuration-manager"
            component={OperationsContainer}
          />

          <PrivateRoute
            role="data manager"
            path="/configuration-manager"
            component={OperationsContainer}
          />

          <Route path="/StateReportAdvance" component={ReportAdvance} />
          <PrivateRoute role="admin" path="/admin" component={AdminContainer} />
          <PrivateRoute
            role="claribel super admin"
            path="/claribel-super-admin"
            component={ClaribelSuperAdminContainer}
          />
          <Redirect to="/login" />
        </Switch>
        <SwitchRole />
        <SwitchCompany />

        {useIsLoggedIn() ? <Help /> : null}
      </div>
    </Suspense>
    //     <div className="switchBtn">
    //       <label class="switch">
    //         <input
    //           type="checkbox"
    //           onChange={toggleTheme}
    //           checked={theme === "dark"}
    //         />
    //         <span class="slider round"></span>
    //       </label>
    //     </div>
    //   </div>
    // </ThemeContext.Provider>
  );
}
