import {
  GET_CONNECTOR_REQUEST,
  GET_SELECTED_CONNECTORS,
  SELECT_CONNECTOR_REQUEST,
  TOGGLE_SELECTOR,
} from "./actionTypes";

const initialState = {
  connectorsData: [],
  isLoading: true,
};

export const connectorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONNECTOR_REQUEST: {
      const { data } = action;

      return {
        ...state,
        connectorsData: data,
        isLoading: false,
      };
    }

    case GET_SELECTED_CONNECTORS: {
      const { data } = action;

      return {
        ...state,
        connectorsData: data,
        isLoading: false,
      };
    }

    case TOGGLE_SELECTOR: {
      const { data } = action;

      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
