export const SIGININ_REQUEST = 'SIGININ_REQUEST';
export const SIGININ_REQUEST_SUCCESS = 'SIGININ_REQUEST_SUCCESS';
export const SIGININ_REQUEST_FAILURE = ' SIGININ_REQUEST_FAILURE';

export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_REQUEST_SUCCESS = 'SEND_OTP_REQUEST_SUCCESS';
export const SEND_OTP_REQUEST_FAILURE = ' SEND_OTP_REQUEST_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS';
export const SIGNUP_REQUEST_FAILURE = ' SIGNUP_REQUEST_FAILURE';

export const GENERATE_TOKEN_REQUEST = '	GENERATE_TOKEN_REQUEST';
export const GENERATE_TOKEN_REQUEST_SUCCESS = '	GENERATE_TOKEN_REQUEST_SUCCESS';
export const GENERATE_TOKEN_REQUEST_FAILURE = ' 	GENERATE_TOKEN_REQUEST_FAILURE';

export const VALIDATE_OTP_REQUEST = 'VALIDATE_OTP_REQUEST';
export const VALIDATE_OTP_REQUEST_SUCCESS = 'VALIDATE_OTP_REQUEST_SUCCESS';
export const VALIDATE_OTP_REQUEST_FAILURE = ' VALIDATE_OTP_REQUEST_FAILURE';

export const GETUSER_REQUEST = 'GETUSER_REQUEST';
export const GETUSER_REQUEST_SUCCESS = 'GETUSER_REQUEST_SUCCESS';
export const GETUSER_REQUEST_FAILURE = 'GETUSER_REQUEST_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';
