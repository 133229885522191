export const GET_EXECUTION_MODE_DETAILS_REQUEST = 'GET_EXECUTION_MODE_DETAILS_REQUEST';
export const GET_EXECUTION_MODE_DETAILS_REQUEST_SUCCESS = 'GET_EXECUTION_MODE_DETAILS_REQEUST_SUCCESS';
export const GET_EXECUTION_MODE_DETAILS_REQUEST_FAILURE = 'GET_EXECUTION_MODE_DETAILS_REQEUST_FAILURE';




export const GET_EXECUTION_MODE_SELECTION_REQUEST = 'GET_EXECUTION_MODE_SELECTION_REQUEST';
export const GET_EXECUTION_MODE_SELECTION_REQUEST_SUCCESS = 'GET_EXECUTION_MODE_SELECTION_REQEUST_SUCCESS';
export const GET_EXECUTION_MODE_SELECTION_REQUEST_FAILURE = 'GET_EXECUTION_MODE_SELECTION_REQEUST_FAILURE';



export const POST_EXECUTION_MODE_SELECTION_REQUEST = 'POST_EXECUTION_MODE_SELECTION_REQUEST';
export const POST_EXECUTION_MODE_SELECTION_REQUEST_SUCCESS = 'POST_EXECUTION_MODE_SELECTION_REQEUST_SUCCESS';
export const POST_EXECUTION_MODE_SELECTION_REQUEST_FAILURE = 'POST_EXECUTION_MODE_SELECTION_REQEUST_FAILURE';

