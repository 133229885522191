import {
	SET_BUDGET_AND_PROGRAM_MODULE,
	SET_COMPLIANCE_MODULE,
	SET_CYBER_RISK_MODULE,
	SET_INSIDER_THREAT_MODULE,
	SET_REPORTS_MODULE,

} from './actionTypes';

const initialState = {
	cyberRisk: undefined,
	compliance: undefined,
	budgetProgram: undefined,
	insiderThreat: undefined,
	reports: undefined,
};

export const moduleAssignmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_BUDGET_AND_PROGRAM_MODULE: {
			const { data } = action;
			if (!data) {
				return { ...state };
			}
			const shouldAssign = Object.values(data).some((item) => item);
			if (!shouldAssign) {
				return { ...state };
			}
			return {
				...state,
				budgetProgram: data,
			};
		}
		case SET_COMPLIANCE_MODULE: {
			const { data } = action;
			if (!data) {
				return { ...state };
			}
			const shouldAssign = Object.values(data).some((item) => item);
			if (!shouldAssign) {
				return { ...state };
			}

			return {
				...state,
				compliance: data,
			};
		}
		case SET_CYBER_RISK_MODULE: {
			const { data } = action;
			if (!data) {
				return { ...state };
			}
			const shouldAssign = Object.values(data).some((item) => item);
			if (!shouldAssign) {
				return { ...state };
			}

			return {
				...state,
				cyberRisk: data,
			};
		}
		case SET_INSIDER_THREAT_MODULE: {
			const { data } = action;
			if (!data) {
				return { ...state };
			}
			const shouldAssign = Object.values(data).some((item) => item);
			if (!shouldAssign) {
				return { ...state };
			}

			return {
				...state,
				insiderThreat: data,
			};
		}
		case SET_REPORTS_MODULE: {
			const { data } = action;
			if (!data) {
				return { ...state };
			}
			const shouldAssign = Object.values(data).some((item) => item);
			if (!shouldAssign) {
				return { ...state };
			}

			return {
				...state,
				reports: data,
			};
		}
		default:
			return {
				...state,
			};
	}
};
